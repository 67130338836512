import * as React from 'react';
import { API_URL } from 'app/constants';
import axios from 'axios';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import {
  useTheme,
  TextField,
  InputAdornment,
  Grid,
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { call } from '../../../utils/apiCall';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageVerify from 'app/utils/imageVerify';
import {
  PhoneNumberUtil,
  // using PNF alias to follow along with documentaion
  PhoneNumberFormat as PNF
} from 'google-libphonenumber';
import moment from 'moment';
// import infoIcon from '../../assets/img/infoIcon.png';
import { useEffect } from 'react';
import useAuth from 'app/hooks/useAuth';
import { getRoleAndpermission } from 'app/utils/utils';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const styles = styled(TextField)({
  '*.Mui-focused': {
    borderColor: 'transparent',
    outline: 'none'
  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
      // width: 250
    }
  }
};

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, textTransform: 'capitalize' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function ViewReportModel(props) {
  const { modal, toggle, data, action, otherFunction } = props;
  const { palette } = useTheme();

  const { roleAndPermission } = useAuth();

  return (
    <div>
      <BootstrapDialog maxWidth={'xs'} aria-labelledby="customized-dialog-title" open={modal}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={toggle}>
          View Report
        </BootstrapDialogTitle>
        <DialogContent>
          <form>
            <Grid container spacing={3}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <label
                  htmlFor="phoneInput"
                  style={{
                    display: 'block',
                    fontSize: '14px',
                    color: '#555'
                  }}
                >
                  First Name
                </label>
                <TextField
                  fullWidth
                  size="small"
                  type="text"
                  name="First Name"
                  variant="outlined"
                  value={data?.user?.first_name}
                  sx={{ mb: 3 }}
                  disabled
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <label
                  htmlFor="phoneInput"
                  style={{
                    display: 'block',
                    fontSize: '14px',
                    color: '#555'
                  }}
                >
                  Last Name
                </label>
                <TextField
                  fullWidth
                  size="small"
                  type="text"
                  name="text"
                  variant="outlined"
                  value={data?.user?.last_name}
                  sx={{ mb: 3 }}
                  disabled
                />
              </Grid>
            </Grid>
            <div>
              <label
                htmlFor="phoneInput"
                style={{
                  display: 'block',
                  fontSize: '14px',
                  color: '#555'
                }}
              >
                Email
              </label>
              <TextField
                fullWidth
                size="small"
                type="email"
                name="email"
                variant="outlined"
                // onBlur={handleBlur}
                value={data?.user?.email}
                sx={{ mb: 3 }}
                disabled
              />
            </div>
            <div>
              <label
                htmlFor="phoneInput"
                style={{
                  display: 'block',
                  fontSize: '14px',
                  color: '#555'
                }}
              >
                Report Type
              </label>
              <TextField
                fullWidth
                size="small"
                type="text"
                name="report_type"
                variant="outlined"
                // onBlur={handleBlur}
                value={data?.report?.type}
                sx={{ mb: 3 }}
                disabled
              />
            </div>
            <div>
              <label
                htmlFor="phoneInput"
                style={{
                  display: 'block',
                  fontSize: '14px',
                  color: '#555'
                }}
              >
                Type
              </label>
              <TextField
                fullWidth
                size="small"
                type="text"
                name="type"
                variant="outlined"
                // onBlur={handleBlur}
                value={data?.type}
                sx={{ mb: 3 }}
                disabled
              />
            </div>
            <div>
              <label
                htmlFor="phoneInput"
                style={{
                  display: 'block',
                  fontSize: '14px',
                  color: '#555'
                }}
              >
                Message
              </label>
              <TextField
                fullWidth
                size="small"
                type="text"
                name="message"
                variant="outlined"
                // onBlur={handleBlur}
                value={data?.message}
                sx={{ mb: 3 }}
                disabled
              />
            </div>
          </form>
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={toggle}>
            Save changes
          </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </div>
  );
}
