import * as React from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import 'react-toastify/dist/ReactToastify.css';
import { STAGE_FRONTEND } from 'app/constants';
import { useEffect } from 'react';
import { useState } from 'react';
import { call } from 'app/utils/apiCall';
import { useNavigate } from 'react-router-dom';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const StyledTable = styled(Table)(() => ({
  whiteSpace: 'pre',
  marginBottom: '50px',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } }
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0 } }
  },
  '& .MuiTableCell-root': {
    borderBottom: '1px solid #D1DFFF'
  },
  '& .MuiTableRow-root:nth-child(even)': {
    backgroundColor: '#f2f2f2' // Alternate row color
  }
}));

const StyledTableHead = styled(TableCell)(() => ({
  fontSize: '15px',
  fontWeight: 600,
  color: 'white',
  backgroundColor: 'rgba(39, 75, 175, 0.8)',
  '&:first-of-type': {
    borderTopLeftRadius: '15px' // Curved edge on the top-left corner
  },
  '&:last-of-type': {
    borderTopRightRadius: '15px' // Curved edge on the top-right corner
  }
}));

const StyledTableCell = styled(TableCell)(() => ({
  color: `#676A79`
}));

export default function MembersTable(props) {
  const { modal, toggle, tpData } = props;
  const [loading, setLoading] = useState(false);
  const [membersData, setMembersData] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [skipData, setSkipData] = useState(0);

  useEffect(() => {
    setLoading(true);
    call(
      'get',
      `api/v1/admin/talent-pool-members/${tpData?.id}`,
      { limit: rowsPerPage, skip: skipData },
      null
    )
      .then((res) => {
        setLoading(false);
        setMembersData(res.data.data ? res.data.data : []);
        setTotalItems(res.data?.count || 0);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [skipData, page]);

  const handleChangePage = (_, newPage) => {
    setSkipData(newPage * rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setSkipData(0);
  };

  const navigate = useNavigate();

  return (
    <div>
      <BootstrapDialog maxWidth={'md'} aria-labelledby="customized-dialog-title" open={modal}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={toggle}
          style={{ minWidth: '250px' }}
        >
          Members
        </BootstrapDialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Box width="100%" overflow="auto">
              <StyledTable
                style={{ minWidth: '500px', overflowX: 'auto', wordBreak: 'break-word' }}
              >
                <TableHead>
                  <TableRow>
                    <StyledTableHead align="left" style={{ width: '75px', paddingLeft: '20px' }}>
                      ID
                    </StyledTableHead>
                    <StyledTableHead align="center" style={{ width: '130px' }}>
                      Name
                    </StyledTableHead>
                    <StyledTableHead align="center" style={{ width: '130px' }}>
                      Email
                    </StyledTableHead>
                    <StyledTableHead align="center" style={{ width: '130px' }}>
                      NP Member
                    </StyledTableHead>
                    <StyledTableHead align="center" style={{ width: '130px' }}>
                      Profile Url
                    </StyledTableHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {membersData.map((member, index) => (
                    <TableRow key={index}>
                      <StyledTableCell align="left" style={{ paddingLeft: '20px' }}>
                        {member?.user_id}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Link
                          sx={{ cursor: 'pointer' }}
                          onClick={() => navigate(`/manage-user?id=${member?.user?.id}`)}
                        >
                          {member?.user?.first_name
                            ? `${member?.user?.first_name} ${member?.user?.last_name}`
                            : 'TP User'}
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell align="center">{member?.user?.email}</StyledTableCell>
                      <StyledTableCell align="center">
                        {member?.user?.role_id == 1 ? 'Yes' : 'No'}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {member?.user?.first_name || member?.user?.role_id == 1 ? (
                          <Link
                            href={`${STAGE_FRONTEND}/profile/${
                              member?.user?.profile_url || member?.user_id
                            }`}
                            target="_blank"
                          >
                            {`${STAGE_FRONTEND}/profile/${
                              member?.user?.profile_url || member?.user_id
                            }`}
                          </Link>
                        ) : (
                          <>N/A</>
                        )}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </StyledTable>
              <TablePagination
                sx={{ px: 2 }}
                page={page}
                component="div"
                rowsPerPage={rowsPerPage}
                count={totalItems}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[5, 10, 25]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                backIconButtonProps={{ 'aria-label': 'Previous Page' }}
              />
            </Box>
          )}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
