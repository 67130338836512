import * as React from 'react';
import { API_URL } from 'app/constants';
import axios from 'axios';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import { useTheme, TextField, InputAdornment, Grid, Box, Checkbox } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { call } from '../../../utils/apiCall';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageVerify from 'app/utils/imageVerify';
import {
  PhoneNumberUtil,
  // using PNF alias to follow along with documentaion
  PhoneNumberFormat as PNF
} from 'google-libphonenumber';
import { useEffect } from 'react';
import moment from 'moment';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const styles = styled(TextField)({
  '*.Mui-focused': {
    borderColor: 'transparent',
    outline: 'none'
  }
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
      // width: 250
    }
  }
};
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, textTransform: 'capitalize' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function EditWorkerModal(props) {
  const { modal, toggle, data, action, otherFunction } = props;
  const { palette } = useTheme();

  console.log(data);

  const [initialValues, setInitialValues] = useState({
    first_name: data?.user?.first_name || '',
    last_name: data?.user?.last_name || '',
    about: data?.user?.profile?.about || '',
    profile_headline: data?.user?.profile?.profile_headline || '',
    profession: data?.profession || [],
    dob: data?.user?.profile.dob || '',
    experience: data?.experience || 0,
    phone: data?.user?.phone || '',
    whatsapp: data?.whatsapp || '',
    desire_country: data?.desire_country || '',
    nationality: data?.user?.profile?.nationality?.id || '',
    experience_region: data?.experience_region || '',
    work_type: data?.work_type || '',
    religion: data?.religion || '',
    gender: data?.user?.profile?.gender || ''
  });

  const [error, setError] = useState({
    first_name: '',
    last_name: '',
    about: '',
    profile_headline: '',
    profession: '',
    dob: '',
    experience: '',
    phone: '',
    whatsapp: '',
    desire_country: '',
    nationality: '',
    experience_region: '',
    work_type: '',
    religion: '',
    gender: ''
  });

  const textError = palette.error.main;
  const nameRegEx = /^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i;
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [phoneCount, setPhoneCount] = useState('');
  const [whatsappCount, setWhatsappCount] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorEmail, setErrorEmail] = useState('');
  const [errorFirstName, setErrorFirstName] = useState('');
  const [errorLastName, setErrorLastName] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorPhoneNumber, setErrorPhoneNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [rolesData, setRolesData] = useState([]);
  const [selectedRole, setSelectedRole] = useState(0);
  const [imageUrl, setImageUrl] = useState('');
  const [country, setCountry] = useState([]);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const phoneUtil = PhoneNumberUtil.getInstance();

  const [religionList, setReligionList] = useState([]);
  const [experienceRegionList, setExperienceRegionList] = useState([]);
  const [employmentTypeList, setEmploymentTypeList] = useState([]);
  const [professionList, setProfessionList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState('');
  const phoneCountValidation = (phone, count) => {
    let num = JSON.stringify(phone);
    let code = JSON.stringify(count);
    const number = phoneUtil.parse(num, code);

    console.log(phoneUtil.isValidNumber(number), "h'h'h'h");
    return phoneUtil.isValidNumber(number);
  };

  const checkEditValidation = (type) => {
    let stringName = /^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i;

    let formIsValid = true;

    console.log(type);

    if (type == 'all' || type == 'first_name') {
      if (!initialValues?.first_name) {
        setError({ ...error, first_name: 'Enter your first name.' });
        formIsValid = false;
      } else if (!stringName.test(initialValues?.first_name)) {
        setError({ ...error, first_name: 'First name is invalid.' });
        formIsValid = false;
      } else if (initialValues?.first_name?.length > 50) {
        setError({ ...error, first_name: 'Max 50 characters allowed.' });
        formIsValid = false;
      } else {
        setError({ ...error, first_name: '' });
        formIsValid = true;
      }
    }

    if (type == 'all' || type == 'last_name') {
      if (!initialValues?.last_name) {
        setError({ ...error, last_name: 'Enter your last name.' });
        formIsValid = false;
      } else if (!stringName.test(initialValues?.last_name)) {
        setError({ ...error, last_name: 'Last name is invalid.' });
        formIsValid = false;
      } else if (initialValues?.last_name?.length > 50) {
        setError({ ...error, last_name: 'Max 50 characters allowed.' });
        formIsValid = false;
      } else {
        formIsValid = true;
        setError({ ...error, last_name: '' });
      }
    }

    if (type == 'all' || type == 'phone') {
      if (initialValues?.phone === 0) {
        setError({ ...error, phone: 'Please enter the phone number.' });
        formIsValid = false;
      } else if (initialValues?.phone.length < 10) {
        setError({ ...error, phone: 'Please enter the valid number.' });
        formIsValid = false;
      } else if (!phoneCountValidation(initialValues?.phone, phoneCount)) {
        setError({ ...error, phone: 'Please enter the valid number.' });
        formIsValid = false;
      } else {
        formIsValid = true;
        setError({ ...error, phone: '' });
      }
    }

    if (type == 'all' || type == 'whatsapp') {
      if (initialValues?.whatsapp === 0) {
        setError({ ...error, whatsapp: 'Please enter the whatsapp number.' });
        formIsValid = false;
      } else if (initialValues?.whatsapp.length < 10) {
        setError({ ...error, whatsapp: 'Please enter the valid number.' });
        formIsValid = false;
      } else if (!phoneCountValidation(initialValues?.whatsapp, whatsappCount)) {
        setError({ ...error, whatsapp: 'Please enter the valid number.' });
        formIsValid = false;
      } else {
        formIsValid = true;
        setError({ ...error, whatsapp: '' });
      }
    }

    if (type == 'all' || type == 'desire_country') {
      if (!initialValues?.desire_country) {
        setError({ ...error, desire_country: 'Residence country required' });
        formIsValid = false;
      } else {
        setError({ ...error, desire_country: '' });
        formIsValid = true;
      }
    }

    if (type == 'all' || type == 'experience_region') {
      if (!initialValues?.experience_region) {
        setError({ ...error, experience_region: 'Nationality is required' });
        formIsValid = false;
      } else {
        formIsValid = true;
        setError({ ...error, experience_region: '' });
      }
    }

    if (type == 'all' || type == 'about') {
      if (!initialValues?.about) {
        setError({ ...error, about: 'About is required' });
        formIsValid = false;
      } else {
        formIsValid = true;
        setError({ ...error, about: '' });
      }
    }

    if (type == 'all' || type == 'profile_headline') {
      if (!initialValues?.profile_headline) {
        setError({ ...error, profile_headline: 'Profile headine is  required' });
        formIsValid = false;
      } else {
        formIsValid = true;
        setError({ ...error, profile_headline: '' });
      }
    }

    if (type == 'all' || type == 'dob') {
      if (!initialValues?.dob) {
        setError({ ...error, dob: 'Date of birth is  required' });
        formIsValid = false;
      } else {
        formIsValid = true;
        setError({ ...error, dob: '' });
      }
    }

    if (type === 'all' || type === 'experience') {
      if (initialValues?.experience < 0) {
        setError({ ...error, experience: 'Please add experience ' });
        formIsValid = false;
      } else if (
        new Date().getFullYear() - new Date(initialValues?.dob).getFullYear() - 17 <=
        initialValues?.experience
      ) {
        setError({ ...error, experience: 'Invalid experience as based on date of birth' });
        formIsValid = false;
      } else {
        formIsValid = true;
        setError({ ...error, experience: '' });
      }
    }

    if (type == 'all' || type == 'work_type') {
      if (!initialValues?.work_type) {
        setError({ ...error, work_type: 'Work type is  required' });
        formIsValid = false;
      } else {
        formIsValid = true;
        setError({ ...error, work_type: '' });
      }
    }

    if (type == 'all' || type == 'religion') {
      if (!initialValues?.religion) {
        setError({ ...error, religion: 'Religion is  required' });
        formIsValid = false;
      } else {
        formIsValid = true;
        setError({ ...error, religion: '' });
      }
    }

    if (type == 'all' || type == 'gender') {
      if (!initialValues?.gender) {
        setError({ ...error, gender: 'Gender is  required' });
        formIsValid = false;
      } else {
        formIsValid = true;
        setError({ ...error, gender: '' });
      }
    }

    if (type === 'all' || type === 'profession') {
      if (!initialValues?.profession) {
        setError({ ...error, profession: 'Please add professions for this category ' });
        formIsValid = false;
      } else if (initialValues?.profession?.length > 5) {
        setError({ ...error, profession: 'You can select upto 5 professions only' });
        formIsValid = false;
      } else {
        formIsValid = true;
        setError({ ...error, profession: '' });
      }
    }

    console.log(formIsValid, error);
    return formIsValid;
  };

  const handleEditSubmit = async () => {
    console.log(checkEditValidation('all'));
    if (checkEditValidation('all')) {
      console.log('fffff');
      try {
        const res = await call('patch', `api/v1/admin/wp-worker/${data?.id}`, null, initialValues);
        // setLoading(false);
        toggle();
        otherFunction();
        toast.success(`${data?.type} updated successfully!`, {
          position: toast.POSITION.BOTTOM_LEFT
        });
      } catch (err) {
        console.log(err);
        setErrorMessage(err.response.data.message);
      }
    }
  };

  const getRoles = () => {
    (async () => {
      try {
        const res = await call(
          'get',
          'api/v1/admin/roles',
          { limit: 1, skip: 0, sort: 'asc', pagination: 0 },
          null
        );
        setRolesData(res.data.data ? res.data.data : []);
      } catch (err) {
        console.log(err);
        setRolesData([]);
      }
    })();
  };

  const getReligion = () => {
    (async () => {
      try {
        const res = await call('get', 'api/v1/public/religion-list');
        setReligionList(res.data.data);
      } catch (err) {
        console.log(err);
      }
    })();
  };

  const getExperienceRegion = () => {
    (async () => {
      try {
        const res = await call('get', 'api/v1/public/experience-region');
        setExperienceRegionList(res.data.data);
      } catch (err) {
        console.log(err);
      }
    })();
  };
  const getEmploymentType = () => {
    (async () => {
      try {
        const res = await call('get', 'api/v1/employment-type');
        setEmploymentTypeList(res.data.data);
      } catch (err) {
        console.log(err);
      }
    })();
  };

  const getProfession = (category) => {
    (async () => {
      try {
        const res = await call('get', 'api/v1/public/profession', { category });
        category == undefined || !category
          ? setCategoryList(res?.data.data)
          : setProfessionList(res.data.data);
      } catch (err) {
        console.log(err);
      }
    })();
  };

  useEffect(() => {
    setInitialValues({
      first_name: data?.user?.first_name || '',
      last_name: data?.user?.last_name || '',
      about: data?.user?.profile?.about || '',
      profile_headline: data?.user?.profile?.profile_headline || '',
      profession: data?.profession || [],
      dob: data?.user?.profile.dob || '',
      experience: data?.experience || 0,
      phone: data?.user?.phone || '',
      whatsapp: data?.whatsapp || '',
      desire_country: data?.desire_country || '',
      nationality: data?.user?.profile?.nationality?.id || '',
      experience_region: data?.experience_region || '',
      work_type: data?.work_type || '',
      religion: data?.religion || '',
      gender: data?.user?.profile?.gender || ''
    });
    setCategory(data?.category);
    getRoles();
    getReligion();
    getEmploymentType();
    getExperienceRegion();
    getProfession(undefined);
    (async () => {
      try {
        const res = await call('get', 'api/v1/country', null, null, null);
        console.log(res);
        setCountry(res?.data?.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [data, action]);

  useEffect(() => {
    if (category) getProfession(category);
  }, [category]);

  return (
    <div>
      <BootstrapDialog width={'lg'} aria-labelledby="customized-dialog-title" open={modal}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={toggle}>
          {action == 'view' ? `View ${data?.type}` : `Edit ${data?.type}`}
        </BootstrapDialogTitle>
        <DialogContent>
          <form>
            <Grid container spacing={3}>
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      name="first_name"
                      label="First Name"
                      variant="outlined"
                      onBlur={() => checkEditValidation('first_name')}
                      value={initialValues?.first_name}
                      onChange={(e) =>
                        setInitialValues({ ...initialValues, first_name: e.target.value })
                      }
                      helperText={error?.first_name}
                      error={Boolean(error?.first_name)}
                      sx={{ mb: 1, mt: 2 }}
                      disabled={action === 'view' ? true : false}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      name="last_name"
                      label="Last Name"
                      variant="outlined"
                      onBlur={() => checkEditValidation('last_name')}
                      value={initialValues?.last_name}
                      onChange={(e) =>
                        setInitialValues({ ...initialValues, last_name: e.target.value })
                      }
                      helperText={error?.last_name}
                      error={Boolean(error?.last_name)}
                      disabled={action === 'view' ? true : false}
                      sx={{ mb: 1, mt: 2 }}
                    />
                  </Grid>
                </Grid>
                <TextField
                  fullWidth
                  size="small"
                  type="text"
                  name="about"
                  label="About"
                  variant="outlined"
                  onBlur={() => checkEditValidation('about')}
                  value={initialValues?.about}
                  onChange={(e) => setInitialValues({ ...initialValues, about: e.target.value })}
                  helperText={error?.about}
                  error={Boolean(error?.about)}
                  sx={{ mb: 1, mt: 2 }}
                  disabled={action === 'view'}
                  multiline
                  rows={4}
                />

                <TextField
                  fullWidth
                  size="small"
                  type="text"
                  name="profile_headline"
                  label="Profile Headline"
                  variant="outlined"
                  onBlur={() => checkEditValidation('profile_headline')}
                  value={initialValues?.profile_headline}
                  onChange={(e) =>
                    setInitialValues({ ...initialValues, profile_headline: e.target.value })
                  }
                  helperText={error?.profile_headline}
                  error={Boolean(error?.profile_headline)}
                  sx={{ mb: 3, mt: 2 }}
                  disabled={action === 'view' ? true : false}
                />
                <div style={{ marginBottom: '20px' }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label" error={Boolean(error?.religion)}>
                      Religion
                    </InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      name="religion"
                      value={initialValues?.religion || ''}
                      onChange={(e) =>
                        setInitialValues({ ...initialValues, religion: e.target.value })
                      }
                      onBlur={() => checkEditValidation('religion')}
                      disabled={action === 'view'}
                      error={Boolean(error?.religion)}
                      displayEmpty
                      MenuProps={MenuProps}
                      label="Religion"
                    >
                      <MenuItem value="" disabled>
                        Select Religion
                      </MenuItem>
                      {religionList.map((option) => (
                        <MenuItem key={option.id} value={option.religion}>
                          {option.religion}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography sx={{ color: textError }}>{error?.religion}</Typography>
                  </FormControl>
                </div>

                <div style={{ marginBottom: '20px' }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label" error={Boolean(error?.gender)}>
                      Gender
                    </InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      name="gender"
                      value={initialValues?.gender || ''}
                      onChange={(e) =>
                        setInitialValues({ ...initialValues, gender: e.target.value })
                      }
                      onBlur={() => checkEditValidation('gender')}
                      disabled={action === 'view'}
                      error={Boolean(error?.gender)}
                      displayEmpty
                      MenuProps={MenuProps}
                      label="Gender"
                    >
                      <MenuItem value="" disabled>
                        Select Gender
                      </MenuItem>
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                    </Select>
                    <Typography sx={{ color: textError }}>{error?.gender}</Typography>
                  </FormControl>
                </div>
                <div style={{ marginBottom: '20px' }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label" error={Boolean(error?.experience)}>
                      Experience
                    </InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      name="experience"
                      value={initialValues?.experience || ''}
                      onChange={(e) =>
                        setInitialValues({ ...initialValues, experience: e.target.value })
                      }
                      onBlur={() => checkEditValidation('experience')}
                      disabled={action === 'view'}
                      error={Boolean(error?.experience)}
                      displayEmpty
                      MenuProps={MenuProps}
                      label="Experience"
                    >
                      <MenuItem value="" disabled>
                        Select Experience
                      </MenuItem>
                      <MenuItem value="0">Fresher</MenuItem>
                      <MenuItem value="1">1 Year</MenuItem>
                      <MenuItem value="2">2 Years</MenuItem>
                      <MenuItem value="3">3 Years</MenuItem>
                      <MenuItem value="4">4 Years</MenuItem>
                      <MenuItem value="5">5 Years</MenuItem>
                      <MenuItem value="6">6 Years</MenuItem>
                      <MenuItem value="7">7 Years</MenuItem>
                      <MenuItem value="8">8 Years</MenuItem>
                      <MenuItem value="9">9 Years</MenuItem>
                      <MenuItem value="10">10 Years</MenuItem>
                      <MenuItem value="11">11 Years</MenuItem>
                      <MenuItem value="12">12 Years</MenuItem>
                      <MenuItem value="12">13 Years</MenuItem>
                      <MenuItem value="14">14 Years</MenuItem>
                      <MenuItem value="15">15 Years</MenuItem>
                      <MenuItem value="16">16 Years</MenuItem>
                      <MenuItem value="17">17 Years</MenuItem>
                      <MenuItem value="18">18 Years</MenuItem>
                    </Select>
                    <Typography sx={{ color: textError }}>{error?.experience}</Typography>
                  </FormControl>
                </div>
                <div style={{ marginBottom: '20px' }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">Category</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      name="category"
                      value={category || ''}
                      onChange={(e) => {
                        setCategory(e.target.value);
                        setInitialValues({ ...initialValues, profession: [] });
                      }}
                      disabled={action == 'view'}
                      MenuProps={MenuProps}
                      label="Category"
                    >
                      <MenuItem value="" disabled>
                        Select Category
                      </MenuItem>
                      {categoryList.map((option) => (
                        <MenuItem key={option.id} value={option.category}>
                          {option.category}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div style={{ marginBottom: '20px' }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label" error={Boolean(error?.profession)}>
                      Profession
                    </InputLabel>

                    <Select
                      fullWidth
                      size="small"
                      multiple
                      name="profession"
                      value={initialValues?.profession || []} // Use an array for multi-select values
                      onChange={(e) => {
                        const { value } = e.target;
                        setInitialValues({
                          ...initialValues,
                          profession: typeof value === 'string' ? value.split(',') : value // Ensure the value is an array
                        });
                      }}
                      renderValue={(selected) => selected.join(', ')}
                      disabled={!category || action == 'view'} // Display selected values as a comma-separated string
                      MenuProps={MenuProps}
                      label="Profession"
                    >
                      <MenuItem value="" disabled>
                        Select Profession
                      </MenuItem>
                      {professionList.map((option) => (
                        <MenuItem key={option.id} value={option.name}>
                          <Checkbox checked={initialValues?.profession.indexOf(option.name) > -1} />
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography sx={{ color: textError }}>{error?.profession}</Typography>
                  </FormControl>
                </div>
                <div style={{ marginBottom: '20px' }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label" error={Boolean(error?.nationality)}>
                      Nationality
                    </InputLabel>

                    <Select
                      fullWidth
                      size="small"
                      name="nationality"
                      value={initialValues?.nationality || ''}
                      onChange={(e) =>
                        setInitialValues({ ...initialValues, nationality: e.target.value })
                      }
                      onBlur={() => checkEditValidation('nationality')}
                      disabled={action === 'view'}
                      error={Boolean(error?.nationality)}
                      displayEmpty
                      MenuProps={MenuProps}
                      label="Nationality"
                    >
                      <MenuItem value="" disabled>
                        Select Nationality
                      </MenuItem>
                      {country.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography sx={{ color: textError }}>{error?.nationality}</Typography>
                  </FormControl>
                </div>

                <div style={{ marginBottom: '20px' }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label" error={Boolean(error?.work_type)}>
                      Employment Type
                    </InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      name="work_type"
                      value={initialValues?.work_type || ''}
                      onChange={(e) =>
                        setInitialValues({ ...initialValues, work_type: e.target.value })
                      }
                      onBlur={() => checkEditValidation('work_type')}
                      disabled={action === 'view'}
                      error={Boolean(error?.work_type)}
                      displayEmpty
                      MenuProps={MenuProps}
                      label="Employment Type"
                    >
                      <MenuItem value="" disabled>
                        Select Employment Type
                      </MenuItem>
                      {employmentTypeList.map((option) => (
                        <MenuItem key={option.id} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography sx={{ color: textError }}>{error?.work_type}</Typography>
                  </FormControl>
                </div>

                <div style={{ marginBottom: '20px' }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label" error={Boolean(error?.work_type)}>
                      Residence Country
                    </InputLabel>

                    <Select
                      fullWidth
                      size="small"
                      name="email"
                      value={initialValues?.desire_country || ''}
                      onChange={(e) =>
                        setInitialValues({ ...initialValues, desire_country: e.target.value })
                      }
                      onBlur={() => checkEditValidation('desire_country')}
                      disabled={action === 'view'}
                      error={Boolean(error?.desire_country)}
                      displayEmpty
                      MenuProps={MenuProps}
                      label="Residence Country"
                    >
                      <MenuItem value="" disabled>
                        Select Residence Country
                      </MenuItem>
                      {country.map((option) => (
                        <MenuItem key={option.id} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography sx={{ color: textError }}>{error?.desire_country}</Typography>
                  </FormControl>
                </div>

                <div style={{ marginBottom: '5px' }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label" error={Boolean(error?.work_type)}>
                      Experience Region
                    </InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      name="experience_region"
                      value={initialValues?.experience_region || ''}
                      onChange={(e) =>
                        setInitialValues({ ...initialValues, experience_region: e.target.value })
                      }
                      onBlur={() => checkEditValidation('experience_region')}
                      disabled={action === 'view'}
                      error={Boolean(error?.experience_region)}
                      displayEmpty
                      MenuProps={MenuProps}
                      label="Experience Region"
                    >
                      <MenuItem value="" disabled>
                        Select Experience Region
                      </MenuItem>
                      {experienceRegionList.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option?.name} {','}
                          {option?.region}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography sx={{ color: textError }}>{error?.experience_region}</Typography>
                  </FormControl>
                </div>

                <TextField
                  fullWidth
                  size="small"
                  type="date"
                  name="dob"
                  label="Date Of Birth"
                  variant="outlined"
                  onBlur={() => checkEditValidation('dob')}
                  value={
                    initialValues?.dob
                      ? moment(initialValues?.dob).format('YYYY-MM-DD')
                      : //   new Date(initialValues.dob).toISOString().split('T')[0]
                        ''
                  }
                  onChange={(e) => setInitialValues({ ...initialValues, dob: e.target.value })}
                  helperText={error?.dob}
                  error={Boolean(error?.dob)}
                  disabled={action === 'view'}
                  sx={{ mb: 1, mt: 2 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>
            <div style={{ marginBottom: '10px' }}>
              <label
                htmlFor="phoneInput"
                style={{
                  display: 'block',
                  marginBottom: '6px',
                  fontSize: '14px',
                  color: '#555'
                }}
              >
                Phone Number{' '}
              </label>
              <PhoneInput
                onBlur={() => checkEditValidation('phone')}
                country={'ae'}
                disabled={action === 'view' ? true : false}
                placeholder="Phone"
                inputStyle={
                  action == 'view'
                    ? {
                        padding: '8.5px 14px 8.5px 58px',
                        width: '100%',
                        opacity: '0.5'
                      }
                    : {
                        padding: '8.5px 14px 8.5px 58px',
                        width: '100%'
                      }
                }
                value={initialValues?.phone}
                size="small"
                countryCodeEditable={false}
                specialLabel={''}
                onChange={(value, country, e, formattedValue) => {
                  setInitialValues({
                    ...initialValues,
                    phone: `+${country.dialCode}-${value.slice(country.dialCode.length)}`
                  });
                  setPhoneCount(country.countryCode);
                }}
              />
              {error?.phone && <Typography sx={{ color: textError }}>{error?.phone}</Typography>}
            </div>
            <div style={{ marginBottom: '5px' }}>
              <label
                htmlFor="phoneInput"
                style={{
                  display: 'block',
                  marginBottom: '6px',
                  fontSize: '14px',
                  color: '#555'
                }}
              >
                Whatsapp Number{' '}
              </label>
              <PhoneInput
                onBlur={() => checkEditValidation('whatsapp')}
                country={'ae'}
                disabled={action === 'view' ? true : false}
                placeholder="Whatsapp"
                inputStyle={
                  action == 'view'
                    ? {
                        padding: '8.5px 14px 8.5px 58px',
                        width: '100%',
                        opacity: '0.5'
                      }
                    : {
                        padding: '8.5px 14px 8.5px 58px',
                        width: '100%'
                      }
                }
                value={initialValues?.whatsapp}
                size="small"
                countryCodeEditable={false}
                specialLabel={''}
                onChange={(value, country, e, formattedValue) => {
                  setInitialValues({
                    ...initialValues,
                    whatsapp: `+${country.dialCode}-${value.slice(country.dialCode.length)}`
                  });
                  setWhatsappCount(country.countryCode);
                }}
              />
              {error?.whatsapp && (
                <Typography sx={{ color: textError }}>{error?.whatsapp}</Typography>
              )}
            </div>

            <Button
              fullWidth
              variant="contained"
              color="primary"
              // onClick={handleFormSubmit}
              // loading={loading}
              sx={{ mt: 4, px: 10, backgroundColor: '#0432A3' }}
              onClick={() => (action == 'view' ? toggle() : handleEditSubmit())}
            >
              {action == 'view' ? 'Close' : `Update`}
            </Button>
            {errorMessage && <Typography sx={{ color: textError }}>{errorMessage}</Typography>}
          </form>
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={toggle}>
            Save changes
          </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </div>
  );
}
