import * as React from 'react';
import { API_URL } from 'app/constants';
import axios from 'axios';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import { useTheme, TextField, InputAdornment, Grid, Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { call } from '../../../utils/apiCall';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageVerify from 'app/utils/imageVerify';
import {
  PhoneNumberUtil,
  // using PNF alias to follow along with documentaion
  PhoneNumberFormat as PNF
} from 'google-libphonenumber';
import { useEffect } from 'react';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const styles = styled(TextField)({
  '*.Mui-focused': {
    borderColor: 'transparent',
    outline: 'none'
  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
      // width: 250
    }
  }
};

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, textTransform: 'capitalize' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function EditSeekerModal(props) {
  const { modal, toggle, data, action, otherFunction } = props;
  const { palette } = useTheme();

  const [initialValues, setInitialValues] = useState({
    // email: data?.email || "",
    phone: data?.user?.phone || '',
    whatsapp: data?.whatsapp || '',
    first_name: data?.user?.first_name || '',
    last_name: data?.user?.last_name || '',
    experience_region: data?.experience_region || '',
    desire_country: data?.desire_country || ''
  });

  const [error, setError] = useState({
    // email: "",
    phone: '',
    whatsapp: '',
    first_name: '',
    last_name: '',
    experience_region: '',
    desire_country: ''
  });

  const textError = palette.error.main;
  const nameRegEx = /^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i;
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [phoneCount, setPhoneCount] = useState('');
  const [whatsappCount, setWhatsappCount] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorEmail, setErrorEmail] = useState('');
  const [errorFirstName, setErrorFirstName] = useState('');
  const [errorLastName, setErrorLastName] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorPhoneNumber, setErrorPhoneNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [rolesData, setRolesData] = useState([]);
  const [selectedRole, setSelectedRole] = useState(0);
  const [imageUrl, setImageUrl] = useState('');
  const [country, setCountry] = useState([]);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const phoneUtil = PhoneNumberUtil.getInstance();

  const phoneCountValidation = (phone, count) => {
    let num = JSON.stringify(phone);
    let code = JSON.stringify(count);
    const number = phoneUtil.parse(num, code);

    //console.log(phoneUtil.isValidNumber(number));
    return phoneUtil.isValidNumber(number);
  };

  const checkEditValidation = (type) => {
    let stringName = /^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i;

    let formIsValid = true;

    console.log(type);

    if (type == 'all' || type == 'first_name') {
      if (!initialValues?.first_name) {
        setError({ ...error, first_name: 'Enter your first name.' });
        formIsValid = false;
      } else if (!stringName.test(initialValues?.first_name)) {
        setError({ ...error, first_name: 'First name is invalid.' });
        formIsValid = false;
      } else if (initialValues?.first_name?.length > 50) {
        setError({ ...error, first_name: 'Max 50 characters allowed.' });
        formIsValid = false;
      } else {
        setError({ ...error, first_name: '' });
      }
    }

    if (type == 'all' || type == 'last_name') {
      if (!initialValues?.last_name) {
        setError({ ...error, last_name: 'Enter your last name.' });
        formIsValid = false;
      } else if (!stringName.test(initialValues?.last_name)) {
        setError({ ...error, last_name: 'Last name is invalid.' });
        formIsValid = false;
      } else if (initialValues?.last_name?.length > 50) {
        setError({ ...error, last_name: 'Max 50 characters allowed.' });
        formIsValid = false;
      } else {
        setError({ ...error, last_name: '' });
      }
    }

    if (type == 'all' || type == 'phone') {
      if (initialValues?.phone === 0) {
        setError({ ...error, phone: 'Please enter the phone number.' });
        formIsValid = false;
      } else if (initialValues?.phone.length < 10) {
        setError({ ...error, phone: 'Please enter the valid number.' });
        formIsValid = false;
      } else if (!phoneCountValidation(initialValues?.phone, phoneCount)) {
        setError({ ...error, phone: 'Please enter the valid number.' });
        formIsValid = false;
      } else {
        setError({ ...error, phone: '' });
      }
    }

    if (type == 'all' || type == 'whatsapp') {
      if (initialValues?.whatsapp === 0) {
        setError({ ...error, whatsapp: 'Please enter the whatsapp number.' });
        formIsValid = false;
      } else if (initialValues?.whatsapp.length < 10) {
        setError({ ...error, whatsapp: 'Please enter the valid number.' });
        formIsValid = false;
      } else if (!phoneCountValidation(initialValues?.whatsapp, whatsappCount)) {
        setError({ ...error, whatsapp: 'Please enter the valid number.' });
        formIsValid = false;
      } else {
        setError({ ...error, whatsapp: '' });
      }
    }

    if (type == 'all' || type == 'desire_country') {
      if (!initialValues?.desire_country) {
        setError({ ...error, desire_country: 'Residence country required' });
        formIsValid = false;
      } else {
        setError({ ...error, desire_country: '' });
      }
    }

    if (type == 'all' || type == 'experience_region') {
      if (!initialValues?.experience_region) {
        setError({ ...error, experience_region: 'Nationality is required' });
        formIsValid = false;
      } else {
        setError({ ...error, experience_region: '' });
      }
    }

    console.log(formIsValid, error);
    return formIsValid;
  };

  const handleEditSubmit = async () => {
    if (checkEditValidation('all')) {
      console.log('fffff');
      try {
        const res = await call('patch', `api/v1/admin/wp-user/${data?.id}`, null, initialValues);
        // setLoading(false);
        toggle();
        otherFunction();
        toast.success(`${data?.type} updated successfully!`, {
          position: toast.POSITION.BOTTOM_LEFT
        });
      } catch (err) {
        console.log(err);
        setErrorMessage(err.response.data.message);
      }
    }
  };

  const getRoles = () => {
    (async () => {
      try {
        const res = await call(
          'get',
          'api/v1/admin/roles',
          { limit: 1, skip: 0, sort: 'asc', pagination: 0 },
          null
        );
        setRolesData(res.data.data ? res.data.data : []);
      } catch (err) {
        console.log(err);
        setRolesData([]);
      }
    })();
  };

  useEffect(() => {
    setInitialValues({
      // email: data?.email || "",
      phone: data?.user?.phone || '',
      whatsapp: data?.whatsapp || '',
      first_name: data?.user?.first_name || '',
      last_name: data?.user?.last_name || '',
      experience_region: data?.experience_region || '',
      desire_country: data?.desire_country || ''
    });
    getRoles();

    (async () => {
      try {
        const res = await call('get', 'api/v1/country', null, null, null);
        console.log(res);
        setCountry(res?.data?.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [data, action]);

  return (
    <div>
      <BootstrapDialog width={'lg'} aria-labelledby="customized-dialog-title" open={modal}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={toggle}>
          {action == 'view' ? `View ${data?.type}` : `Edit ${data?.type}`}
        </BootstrapDialogTitle>
        <DialogContent>
          <form>
            <Grid container spacing={3}>
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      name="First Name"
                      label="First Name"
                      variant="outlined"
                      onBlur={() => checkEditValidation('first_name')}
                      value={initialValues?.first_name}
                      onChange={(e) =>
                        setInitialValues({ ...initialValues, first_name: e.target.value })
                      }
                      helperText={error?.first_name}
                      error={Boolean(error?.first_name)}
                      sx={{ mb: 3, mt: 2 }}
                      disabled={action === 'view' ? true : false}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      name="text"
                      label="Last Name"
                      variant="outlined"
                      onBlur={() => checkEditValidation('last_name')}
                      value={initialValues?.last_name}
                      onChange={(e) =>
                        setInitialValues({ ...initialValues, last_name: e.target.value })
                      }
                      helperText={error?.last_name}
                      error={Boolean(error?.last_name)}
                      disabled={action === 'view' ? true : false}
                      sx={{ mb: 3, mt: 2 }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <div style={{ marginBottom: '12px' }}>
              <label
                htmlFor="phoneInput"
                style={{
                  display: 'block',
                  marginBottom: '6px',
                  fontSize: '14px',
                  color: '#555'
                }}
              >
                Phone Number
              </label>
              <PhoneInput
                id="phoneInput"
                onBlur={() => checkEditValidation('phone')}
                country={'ae'}
                disabled={action === 'view' ? true : false}
                placeholder="Phone"
                inputStyle={
                  action === 'view'
                    ? {
                        padding: '8.5px 14px 8.5px 58px',
                        width: '100%',
                        opacity: '0.5'
                      }
                    : {
                        padding: '8.5px 14px 8.5px 58px',
                        width: '100%'
                      }
                }
                value={initialValues?.phone}
                size="small"
                countryCodeEditable={false}
                specialLabel={''} // You can also add label text here if supported
                onChange={(value, country, e, formattedValue) => {
                  setInitialValues({
                    ...initialValues,
                    phone: `+${country.dialCode}-${value.slice(country.dialCode.length)}`
                  });
                  setPhoneCount(country.countryCode);
                }}
              />
              {error?.phone && <Typography sx={{ color: textError }}>{error?.phone}</Typography>}
            </div>

            {/* <Box sx={{ height: '25px' }}></Box> */}

            <div style={{ marginBottom: '12px' }}>
              <label
                htmlFor="phoneInput"
                style={{
                  display: 'block',
                  marginBottom: '6px',
                  fontSize: '14px',
                  color: '#555'
                }}
              >
                Whatsapp Number
              </label>

              <PhoneInput
                onBlur={() => checkEditValidation('whatsapp')}
                country={'ae'}
                disabled={action === 'view' ? true : false}
                placeholder="Whatsapp"
                inputStyle={
                  action == 'view'
                    ? {
                        padding: '8.5px 14px 8.5px 58px',
                        width: '100%',
                        opacity: '0.5',
                        marginBottom: '12px'
                      }
                    : {
                        padding: '8.5px 14px 8.5px 58px',
                        width: '100%',
                        marginBottom: '12px'
                      }
                }
                value={initialValues?.whatsapp}
                size="small"
                countryCodeEditable={false}
                specialLabel={''}
                onChange={(value, country, e, formattedValue) => {
                  setInitialValues({
                    ...initialValues,
                    whatsapp: `+${country.dialCode}-${value.slice(country.dialCode.length)}`
                  });
                  setWhatsappCount(country.countryCode);
                }}
              />
              {error?.whatsapp && (
                <Typography sx={{ color: textError }}>{error?.whatsapp}</Typography>
              )}
            </div>

            <div style={{ marginBottom: '12px' }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label" error={Boolean(error?.desire_country)}>
                  Residence Country
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  fullWidth
                  size="small"
                  name="email"
                  value={initialValues?.desire_country || ''}
                  onChange={(e) =>
                    setInitialValues({ ...initialValues, desire_country: e.target.value })
                  }
                  onBlur={() => checkEditValidation('desire_country')}
                  disabled={action === 'view'}
                  error={Boolean(error?.desire_country)}
                  displayEmpty
                  MenuProps={MenuProps}
                  label="Residence Country"
                >
                  <MenuItem value="" disabled>
                    Select Residence Country
                  </MenuItem>
                  {country.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
                <Typography style={{ marginTop: '1rem' }} sx={{ color: textError }}>
                  {error?.desire_country}
                </Typography>
              </FormControl>
            </div>
            <div style={{ marginBottom: '12px' }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label" error={Boolean(error?.experience_region)}>
                  Nationality
                </InputLabel>
                <Select
                  fullWidth
                  size="small"
                  name="email"
                  value={initialValues?.experience_region || ''}
                  onChange={(e) =>
                    setInitialValues({ ...initialValues, experience_region: e.target.value })
                  }
                  onBlur={() => checkEditValidation('experience_region')}
                  disabled={action === 'view'}
                  error={Boolean(error?.experience_region)}
                  displayEmpty
                  MenuProps={MenuProps}
                  label="Nationality"
                >
                  <MenuItem value="" disabled>
                    Select Nationality
                  </MenuItem>
                  {country.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
                <Typography sx={{ color: textError }}>{error?.experience_region}</Typography>
              </FormControl>
            </div>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              // onClick={handleFormSubmit}
              // loading={loading}
              sx={{ mt: 4, px: 10, backgroundColor: '#0432A3' }}
              onClick={() => (action == 'view' ? toggle() : handleEditSubmit())}
            >
              {action == 'view' ? 'Close' : `Update`}
            </Button>
            {errorMessage && (
              <Typography style={{ marginTop: '1rem' }} sx={{ color: textError }}>
                {errorMessage}
              </Typography>
            )}
          </form>
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={toggle}>
            Save changes
          </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </div>
  );
}
