import {
  Card,
  Grid,
  styled,
  useTheme,
  MenuItem,
  Select,
  Box,
  Button,
  InputAdornment,
  TextField,
  FormControl,
  InputLabel,
  Switch
} from '@mui/material';
import { Fragment } from 'react';
import PaginationTable from './components/userTable';
import { useEffect, useState } from 'react';
import { call } from '../../utils/apiCall';
import SearchIcon from '@mui/icons-material/Search';
import AlertDialog from './components/Alert';
import PasswordResetModal from './components/ResetPassword';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAuth from 'app/hooks/useAuth';
import { PrintExcel, getRoleAndpermission } from 'app/utils/utils';
import moment from 'moment';
import ViewReportModel from './components/viewReportModel';
import EditReportModel from './components/EditReportModel';
import AlertDialogModel from './components/ResetPassword';

const ContentBox = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' }
}));

const CardHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  marginRight: '.5rem',
  textTransform: 'capitalize'
}));

const SubTitle = styled('span')(({ theme }) => ({
  fontSize: '1rem',
  color: theme.palette.text.secondary
}));

const H2 = styled('h2')(({ theme }) => ({
  fontSize: '24px',
  fontWeight: '600',
  color: '01052D',
  marginTop: '0px',
  marginBottom: '30px'
}));

const ManageReport = () => {
  const { palette } = useTheme();
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editUserModal, setEditUserModal] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [skipData, setSkipData] = useState(0);
  const [searchKey, setSearchKey] = useState();
  const [userId, setUserId] = useState();
  const [userStatus, setUserStatus] = useState();
  const [sortData, setSortData] = useState('desc');
  const [viewModel, setViewModel] = useState(false);
  const [type, setType] = useState('');
  const [typeId, setTypeId] = useState('');

  useEffect(() => {
    getUserData();
  }, [rowsPerPage, skipData, sortData, searchKey, type, typeId]);

  const getUserData = () => {
    (async () => {
      try {
        let query = { limit: rowsPerPage, skip: skipData, sort: sortData };
        if (searchKey) {
          query['search'] = searchKey;
        }
        if (type) {
          query['type'] = type;
        }
        if (typeId) {
          query['type_id'] = typeId;
        }
        const res = await call('get', 'api/v1/admin/get-reports-list', query, null);
        setUsersData(res.data.data ? res.data.data : []);
        setTotalItems(res.data.totalItems ? res.data.totalItems : 0);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setUsersData([]);
      }
    })();
  };

  const blockReport = async (data) => {
    try {
      const res = await call('post', 'api/v1/admin/block-report', {}, data);
      getUserData();
      setBlockModel(false);
    } catch (err) {
      console.log(err);
      setBlockModel(false);
    }
  };

  const { roleAndPermission } = useAuth();

  const [exportLoading, setExportLoading] = useState(false);

  const exportData = async () => {
    setExportLoading(true);
    try {
      const res = await call('get', 'api/v1/admin/get-reports-list', {
        limit: totalItems
      });
      setExportLoading(false);

      PrintExcel(
        res?.data?.data,
        `NP-ScreeningData-${moment(new Date()).format('YYYY-MM-DD')}.xlsx`
      );
    } catch (err) {
      console.log(err);
      setExportLoading(false);
    }
  };

  const [editData, setEditData] = useState();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
      }
    }
  };

  const [blockModel, setBlockModel] = useState(false);
  const [blockData, setBlockData] = useState({});
  return (
    <Fragment>
      <ContentBox className="analytics">
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <H2>Manage Reports</H2>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <div>
                <Button
                  disabled={
                    !getRoleAndpermission(
                      roleAndPermission,
                      'Background Verification Management',
                      'export'
                    )
                  }
                  variant="contained"
                  size="small"
                  onClick={exportData}
                  sx={{ px: 3, py: 0.9, backgroundColor: '#0432A3' }}
                >
                  {exportLoading ? 'Loading...' : 'Export'}
                </Button>
              </div>
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <FormControl fullWidth sx={{ width: '100px' }} size="small">
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Select
                    fullWidth
                    size="small"
                    name="nationality"
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                      setTypeId('');
                    }}
                    MenuProps={MenuProps}
                    label="Type"
                    sx={{ width: '100px' }}
                  >
                    <MenuItem value="">Select Type</MenuItem>
                    <MenuItem value="post">Post</MenuItem>
                    <MenuItem value="profile">Profile</MenuItem>
                    <MenuItem value="comment">Comment</MenuItem>
                    <MenuItem value="campaign">Campaign</MenuItem>
                    <MenuItem value="conversation">Conversation</MenuItem>
                    <MenuItem value="group">Group</MenuItem>
                    <MenuItem value="job">Job</MenuItem>
                    <MenuItem value="page">Page</MenuItem>
                    <MenuItem value="worker_profile">Worker Profile</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  style={{ width: '250px' }}
                  id="search"
                  type="search"
                  // label="Search"
                  placeholder="Enter Type Id"
                  size="small"
                  value={typeId}
                  onChange={(e) => setTypeId(e.target.value)}
                  // sx={{ width: '50%' }}
                  disabled={!type}
                />

                <TextField
                  style={{ width: '500px' }}
                  id="search"
                  type="search"
                  // label="Search"
                  placeholder="Search by message, name, email"
                  size="small"
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                  // sx={{ width: '50%' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
            </div>
            <div style={{ marginTop: '20px' }}>
              <PaginationTable
                data={usersData}
                totalItems={totalItems}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setSkipData={setSkipData}
                sortData={sortData}
                setSortData={setSortData}
                viewModel={viewModel}
                setViewModel={setViewModel}
                setEditData={setEditData}
                blockReport={blockReport}
                blockModel={blockModel}
                setBlockModel={setBlockModel}
                setBlockData={setBlockData}
                setType={setType}
                setTypeId={setTypeId}
              />
            </div>
          </Grid>
        </Grid>
      </ContentBox>
      {viewModel && (
        <ViewReportModel
          modal={viewModel}
          toggle={() => setViewModel(!viewModel)}
          data={editData}
          action={'view'}
        />
      )}

      {editUserModal && (
        <EditReportModel
          modal={editUserModal}
          toggle={() => setEditUserModal(!editUserModal)}
          data={editData}
          action={'view'}
          otherFunction={getUserData}
        />
      )}

      {blockModel && (
        <AlertDialogModel
          modal={blockModel}
          toggle={() => setBlockModel(!blockModel)}
          data={editData}
          title={'Are you sure you want to block?'}
          confirmFunc={() => blockReport(blockData)}
        />
      )}
      <ToastContainer />
    </Fragment>
  );
};

export default ManageReport;
