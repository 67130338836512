import * as React from 'react';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import { Grid, Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useEffect } from 'react';
import { call } from 'app/utils/apiCall';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  }
};

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, textTransform: 'capitalize' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function EditReportModel(props) {
  const { modal, toggle, data, action, otherFunction } = props;
  const [bgData, setBgData] = useState();
  const [updatedData, setUpdatedData] = useState({
    education: [],
    employment: [],
    criminal: {
      screening_status: ''
    },
    overallStatus: ''
  });
  const getUserData = async () => {
    try {
      const query = { id: data?.id };
      const res = await call('get', 'api/v1/admin/screening-request-list', query, null);
      const fetchedData = res.data.data[0];
      setBgData(fetchedData);

      // console.log(fetchedData);
      setUpdatedData({
        education: fetchedData?.education?.length
          ? fetchedData?.education?.map((ele) => {
              return { id: ele?.id, screening_status: ele?.screening_status };
            })
          : [],
        employment: fetchedData?.employment?.length
          ? fetchedData?.employment?.map((ele) => {
              return { id: ele?.id, screening_status: ele?.screening_status };
            })
          : [],
        criminal: {
          screening_status: fetchedData?.criminal ? fetchedData?.criminal?.screening_status : ''
        },
        overallStatus: fetchedData?.backgroundStatus
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const handleChange = (type, index, field, value) => {
    setUpdatedData((prev) => {
      const updated = { ...prev };
      if (type === 'education' || type === 'employment') {
        updated[type][index][field] = value;
      } else if (type === 'criminal') {
        updated[type][field] = value;
      } else {
        updated[type] = value;
      }
      return updated;
    });
  };

  // console.log(updatedData);

  const handleUpdate = async () => {
    try {
      // Validate and call update API
      console.log('Updated Data:', updatedData);
      const res = await call(
        'patch',
        `api/v1/admin//screening-request/${bgData?.remoteOrderNumber}`,
        {},
        updatedData
      );
      toast.success(res?.data?.success, {
        position: toast.POSITION.BOTTOM_LEFT
      });
      toggle();
      otherFunction();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={modal}
        fullWidth={true}
        maxWidth={'lg'}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={toggle}>
          Edit Report - {bgData?.user?.first_name} {bgData?.user?.last_name} |{' '}
          {bgData?.remoteOrderNumber}
        </BootstrapDialogTitle>
        <DialogContent>
          <Box sx={{ padding: 3 }}>
            <Typography variant="h6" gutterBottom></Typography>

            <Grid container spacing={3}>
              {/* Subject Information */}

              <Box width={'100%'}>
                {bgData?.employment?.map((ele, i) => {
                  console.log(ele?.screening_status);
                  return (
                    <Box sx={{ mb: 3, mt: 3, display: 'flex' }}>
                      <Box sx={{ width: '70%' }}>
                        <Typography variant="p" gutterBottom sx={{ fontWeight: 600 }}>
                          Employment #{i + 1} [{ele?.company_name}]
                        </Typography>
                      </Box>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">Screening Status</InputLabel>
                        <Select
                          fullWidth
                          size="small"
                          name="email"
                          value={updatedData['employment'][i]?.screening_status || ''}
                          onChange={(e) =>
                            handleChange('employment', i, 'screening_status', e.target.value)
                          }
                          MenuProps={MenuProps}
                          label="Screening Status"
                        >
                          <MenuItem key={'pending'} value={'pending'}>
                            Pending
                          </MenuItem>
                          <MenuItem key={'Discrepancy'} value={'Discrepancy'}>
                            Discrepancy
                          </MenuItem>
                          <MenuItem key={'Verified'} value={'Verified'}>
                            Verified
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  );
                })}
              </Box>

              <Box width={'100%'}>
                {bgData?.education?.map((ele, i) => {
                  return (
                    <Box sx={{ mb: 3, mt: 3, display: 'flex' }}>
                      <Box sx={{ width: '70%' }}>
                        <Typography variant="p" gutterBottom sx={{ fontWeight: 600 }}>
                          Education #{i + 1} [{ele?.school_name}]
                        </Typography>
                      </Box>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">Screening Status</InputLabel>
                        <Select
                          fullWidth
                          size="small"
                          name="email"
                          value={updatedData['education'][i]?.screening_status || ''}
                          onChange={(e) =>
                            handleChange('education', i, 'screening_status', e.target.value)
                          }
                          MenuProps={MenuProps}
                          label="Screening Status"
                        >
                          <MenuItem key={'pending'} value={'pending'}>
                            Pending
                          </MenuItem>
                          <MenuItem key={'Discrepancy'} value={'Discrepancy'}>
                            Discrepancy
                          </MenuItem>
                          <MenuItem key={'Verified'} value={'Verified'}>
                            Verified
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  );
                })}
              </Box>

              <Box width={'100%'}>
                {Object.keys(bgData?.criminal || {})?.length > 0 && (
                  <Box sx={{ mb: 3, mt: 3, display: 'flex' }}>
                    <Box sx={{ width: '70%' }}>
                      <Typography variant="p" gutterBottom sx={{ fontWeight: 600 }}>
                        Criminality/World Scam
                      </Typography>
                    </Box>
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">Screening Status</InputLabel>
                      <Select
                        fullWidth
                        size="small"
                        name="email"
                        value={updatedData['criminal']?.screening_status || ''}
                        onChange={(e) =>
                          handleChange('criminal', null, 'screening_status', e.target.value)
                        }
                        MenuProps={MenuProps}
                        label="Screening Status"
                      >
                        <MenuItem key={'pending'} value={'pending'}>
                          Pending
                        </MenuItem>
                        <MenuItem key={'Discrepancy'} value={'Discrepancy'}>
                          Discrepancy
                        </MenuItem>
                        <MenuItem key={'Verified'} value={'Verified'}>
                          Verified
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                )}
              </Box>

              <Box width={'100%'}>
                <Box sx={{ mb: 3, mt: 3, display: 'flex' }}>
                  <Box sx={{ width: '70%' }}>
                    <Typography variant="p" gutterBottom sx={{ fontWeight: 600 }}>
                      Overall Status
                    </Typography>
                  </Box>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">Screening Status</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      name="email"
                      value={updatedData['overallStatus'] || ''}
                      onChange={(e) => handleChange('overallStatus', null, null, e.target.value)}
                      MenuProps={MenuProps}
                      label="Screening Status"
                    >
                      <MenuItem key={'pending'} value={'Pending'}>
                        Pending
                      </MenuItem>
                      <MenuItem key={'Success'} value={'Success'}>
                        Success
                      </MenuItem>
                      <MenuItem key={'Failed'} value={'Failed'}>
                        Failed
                      </MenuItem>
                      <MenuItem key={'Completed'} value={'Completed'}>
                        Completed
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ px: 3, py: 0.9, backgroundColor: '#0432A3' }}
                  onClick={handleUpdate}
                >
                  Update
                </Button>
              </Box>
            </Grid>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
