import {
  Box,
  Icon,
  IconButton,
  Link,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { Group } from "@mui/icons-material";
import { STAGE_FRONTEND } from "app/constants";
import { useNavigate } from "react-router-dom";

const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0 } },
  },
  "& .MuiTableCell-root": {
    borderBottom: "1px solid #D1DFFF",
  },
  "& .MuiTableRow-root:nth-child(even)": {
    backgroundColor: "#f2f2f2", // Alternate row color
  },
}));

const StyledTableHead = styled(TableCell)(() => ({
  fontSize: "15px",
  fontWeight: 600,
  color: "white",
  backgroundColor: "rgba(39, 75, 175, 0.8)",
  "&:first-of-type": {
    borderTopLeftRadius: "15px", // Curved edge on the top-left corner
  },
  "&:last-of-type": {
    borderTopRightRadius: "15px", // Curved edge on the top-right corner
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  color: `#676A79`,
}));

const PaginationTable = ({
  data,
  alertModal,
  setAlertModal,
  alertDeleteModal,
  setAlertDeleteModal,
  addTPModal,
  setAddTPModal,
  setEditTPData,
  totalItems,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  setSkipData,
  setTPStatus,
  setSortData,
  sortData,
  setIsView,
  adminModal,
  setAdminModal,
  membersModal,
  setMembersModal,
}) => {
  const navigate = useNavigate();

  const handleChangePage = (_, newPage) => {
    setSkipData(newPage * rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setSkipData(0);
  };

  return (
    <Box>
      <Box overflow="auto">
        <StyledTable
          style={{
            minWidth: "1200px",
            overflowX: "auto",
            wordBreak: "break-word",
          }}
        >
          <TableHead>
            <TableRow>
              <StyledTableHead
                align="left"
                style={{ width: "75px", paddingLeft: "20px" }}
              >
                ID
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "130px" }}>
                Name
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "130px" }}>
                Company Name
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "130px" }}>
                Company Email
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "130px" }}>
                Company Phone
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "130px" }}>
                Owner
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "130px" }}>
                Theme
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "130px" }}>
                URL
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "130px" }}>
                Jobs(active/total)
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "130px" }}>
                Members
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "130px" }}>
                Created On
                {sortData === "desc" ? (
                  <IconButton onClick={() => setSortData("asc")}>
                    <Icon sx={{ color: "white" }}>
                      <Tooltip title="Sort Date">
                        <ArrowDownwardIcon />
                      </Tooltip>
                    </Icon>
                  </IconButton>
                ) : (
                  <IconButton onClick={() => setSortData("desc")}>
                    <Icon sx={{ color: "white" }}>
                      <Tooltip title="Sort Date">
                        <ArrowUpwardIcon />
                      </Tooltip>
                    </Icon>
                  </IconButton>
                )}
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "250px" }}>
                Action
              </StyledTableHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((co, index) => (
              <TableRow key={index}>
                <StyledTableCell align="left" style={{ paddingLeft: "20px" }}>
                  {co?.id}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {co?.company_name}
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ cursor: "pointer" }}>
                  <Link
                    onClick={() => navigate(`/manage-company?id=${co?.Company?.id}`)}
                  >
                    {co?.Company?.name}
                  </Link>
                </StyledTableCell>
                <StyledTableCell align="center">{co?.email}</StyledTableCell>
                <StyledTableCell align="center">
                  {co?.phone || "-"}
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ cursor: "pointer" }}>
                  <Link
                    onClick={() => navigate(`/manage-user?id=${co?.user_id}`)}
                  >
                    {co?.User?.full_name}
                  </Link>
                </StyledTableCell>
                <StyledTableCell align="center">{co?.theme}</StyledTableCell>
                <StyledTableCell align="center">
                  <Link
                    href={`${STAGE_FRONTEND}/tp/portal/${co?.url || co?.id
                      }/dashboard`}
                    target="_blank"
                  >
                    {`${STAGE_FRONTEND}/tp/portal/${co?.url || co?.id
                      }/dashboard`}
                  </Link>
                </StyledTableCell>
                <StyledTableCell align="center">
                  {co?.job_count}/{co?.total_job_count}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Link
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setMembersModal(!membersModal);
                      setEditTPData(co);
                    }}
                  >
                    {co?.member_count}/{co?.total_member_count}
                  </Link>
                </StyledTableCell>
                <StyledTableCell align="center">
                  {moment(co?.createdAt).format("ll")}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <IconButton
                    onClick={() => {
                      setAddTPModal(!addTPModal);
                      setEditTPData(co);
                      setIsView(false);
                    }}
                  >
                    <Tooltip title="Edit">
                      <EditIcon />
                    </Tooltip>
                  </IconButton>

                  <IconButton
                    onClick={() => {
                      setAddTPModal(!addTPModal);
                      setIsView(true);
                      setEditTPData(co);
                    }}
                  >
                    <Tooltip title="View">
                      <VisibilityIcon />
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setAdminModal(!adminModal);
                      setEditTPData(co);
                    }}
                  >
                    <Tooltip title="Admin View">
                      <Group />
                    </Tooltip>
                  </IconButton>
                  <Switch
                    onChange={() => {
                      setAlertModal(!alertModal);
                      setTPStatus(!co?.status);
                      setEditTPData(co);
                    }}
                    checked={co?.status}
                    color="primary"
                    size="small"
                  />
                  <IconButton
                    onClick={() => {
                      setAlertDeleteModal(!alertDeleteModal);
                      setEditTPData(co);
                    }}
                  >
                    <Tooltip title="Delete">
                      <DeleteIcon />
                    </Tooltip>
                  </IconButton>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </Box>
      <TablePagination
        sx={{ px: 2 }}
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        count={totalItems}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ "aria-label": "Next Page" }}
        backIconButtonProps={{ "aria-label": "Previous Page" }}
      />
    </Box>
  );
};

export default PaginationTable;
