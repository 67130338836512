import {
  Card,
  Grid,
  styled,
  useTheme,
  MenuItem,
  Select,
  Box,
  Button,
  InputAdornment,
  TextField,
  FormControl,
  InputLabel,
  Switch
} from '@mui/material';
import { Fragment } from 'react';
import PaginationTable from './components/userTable';
import { useEffect, useState } from 'react';
import { call } from '../../utils/apiCall';
import SearchIcon from '@mui/icons-material/Search';
import AlertDialog from './components/Alert';
import PasswordResetModal from './components/ResetPassword';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAuth from 'app/hooks/useAuth';
import { PrintExcel, getRoleAndpermission } from 'app/utils/utils';
import moment from 'moment';
import EditSeekerModal from './components/EditSeekerModel';
import EditWorkerModal from './components/EditWorkerModel';
import AlertDialogModel from './components/ResetPassword';
import { useSearchParams } from 'react-router-dom';

const ContentBox = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' }
}));

const CardHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  marginRight: '.5rem',
  textTransform: 'capitalize'
}));

const SubTitle = styled('span')(({ theme }) => ({
  fontSize: '1rem',
  color: theme.palette.text.secondary
}));

const H2 = styled('h2')(({ theme }) => ({
  fontSize: '24px',
  fontWeight: '600',
  color: '01052D',
  marginTop: '0px',
  marginBottom: '30px'
}));

const ManageWorkerpool = () => {
  const [searchParams] = useSearchParams();
  const { palette } = useTheme();
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addUserModal, setAddUserModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [alertDeleteModal, setAlertDeleteModal] = useState(false);
  const [resetPassModal, setResetPassModal] = useState(false);
  const [totalItems, setTotalItems] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [skipData, setSkipData] = useState(0);
  const [searchKey, setSearchKey] = useState();
  const [userId, setUserId] = useState();
  const [userStatus, setUserStatus] = useState();
  const [sortData, setSortData] = useState('asc');
  const [experienceRegionList, setExperienceRegionList] = useState([]);
  const [experience_region, setExperienceRegion] = useState(null);
  const [mode, setMode] = useState(null);
  const [sortMode, setSortMode] = useState('asc');

  const getExperienceRegion = () => {
    (async () => {
      try {
        const res = await call('get', 'api/v1/public/experience-region');
        setExperienceRegionList(res.data.data);
      } catch (err) {
        console.log(err);
      }
    })();
  };

  useEffect(() => {
    getExperienceRegion();
  }, []);

  useEffect(() => {
    getUserData();
  }, [searchKey, skipData, rowsPerPage, sortData, experience_region, mode, sortMode]);

  const getUserData = () => {
    (async () => {
      try {
        let query = {
          limit: rowsPerPage,
          skip: skipData,
          sort: sortData
        };
        if (searchKey) {
          query['search'] = searchKey;
        }
        if (experience_region) query['experience_region'] = experience_region;
        if (mode) query['mode'] = mode;
        if (sortMode) query['sortMode'] = sortMode;
        if (searchParams?.get('id')) query['id'] = searchParams?.get('id');

        const res = await call('get', 'api/v1/admin/wp-users', query, null);
        setUsersData(res.data.data ? res.data.data : []);
        setTotalItems(res.data.totalItems ? res.data.totalItems : 0);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setUsersData([]);
      }
    })();
  };

  const deleteUser = async (id) => {
    try {
      const res = await call('delete', `api/v1/admin/wp-users/${id}`, null, null);
      setLoading(false);
      getUserData();
      // let letnewData = usersData.filter((item) => item.id !== id);
      // setUsersData(letnewData);
      setAlertDeleteModal(!alertDeleteModal);
      toast.success('User Deleted Successfully!', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } catch (err) {
      console.log(err);
    }
  };

  const blockUser = async (id) => {
    let data = {
      status: userStatus === 'block' ? false : true
    };
    try {
      const res = await call('patch', `api/v1/admin/block-wp-user/${id}`, null, data);
      setLoading(false);
      getUserData();
      setAlertModal(!alertModal);
      toast.success(
        userStatus === 'block' ? 'User Block Successfully!' : 'User Active Successfully!',
        {
          position: toast.POSITION.BOTTOM_LEFT
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const publicUser = async (id) => {
    try {
      const res = await call('patch', `api/v1/admin/wp-public-profile/${id}`);
      setLoading(false);
      getUserData();
      setResetPassModal(!resetPassModal);
      setUserId('');
      toast.success('Profile Updated Successfully!', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } catch (err) {
      console.log(err);
    }
  };

  const { roleAndPermission } = useAuth();

  const [exportLoading, setExportLoading] = useState(false);

  const exportData = async () => {
    setExportLoading(true);
    try {
      const res = await call('get', 'api/v1/admin/wp-users', {
        limit: totalItems
      });
      setExportLoading(false);
      PrintExcel(
        res?.data?.data,
        `NP-WorkerpoolData-${moment(new Date()).format('YYYY-MM-DD')}.xlsx`
      );
    } catch (err) {
      console.log(err);
      setExportLoading(false);
    }
  };

  const [editData, setEditData] = useState();
  const [isView, setIsView] = useState(false);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
        // width: 250
      }
    }
  };
  return (
    <Fragment>
      <ContentBox className="analytics">
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <H2>Manage Workerpool Users</H2>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <div>
                <Button
                  disabled={
                    !getRoleAndpermission(roleAndPermission, 'Workerpool Management', 'export')
                  }
                  variant="contained"
                  size="small"
                  onClick={exportData}
                  sx={{ px: 3, py: 0.9, backgroundColor: '#0432A3' }}
                >
                  {exportLoading ? 'Loading...' : 'Export'}
                </Button>
              </div>
              <div style={{ marginRight: '20px', display: 'flex', gap: 30 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">Experience Region</InputLabel>
                  <Select
                    fullWidth
                    size="small"
                    name="experience_region"
                    value={experience_region}
                    onChange={(e) => setExperienceRegion(e.target.value)}
                    MenuProps={MenuProps}
                    label="Experience Region"
                  >
                    <MenuItem value="">Select Experience Region</MenuItem>
                    {experienceRegionList.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option?.name} {','}
                        {option?.region}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">Mode</InputLabel>
                  <Select
                    fullWidth
                    size="small"
                    name="experience_region"
                    value={mode}
                    onChange={(e) => setMode(e.target.value)}
                    MenuProps={MenuProps}
                    label="Mode"
                  >
                    <MenuItem value="">Select Mode</MenuItem>
                    <MenuItem key={'public'} value={'public'}>
                      Public
                    </MenuItem>
                    <MenuItem key={'private'} value={'private'}>
                      Private
                    </MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  style={{ width: '500px' }}
                  id="search"
                  type="search"
                  // label="Search"
                  placeholder="Search..."
                  size="small"
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                  // sx={{ width: '50%' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <PaginationTable
                data={usersData}
                alertModal={alertModal}
                setAlertModal={setAlertModal}
                alertDeleteModal={alertDeleteModal}
                setAlertDeleteModal={setAlertDeleteModal}
                addUserModal={addUserModal}
                setAddUserModal={setAddUserModal}
                resetPassModal={resetPassModal}
                setResetPassModal={setResetPassModal}
                totalItems={totalItems}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setSkipData={setSkipData}
                setUserId={setUserId}
                userStatus={userStatus}
                setUserStatus={setUserStatus}
                sortData={sortData}
                setSortData={setSortData}
                setEditData={setEditData}
                setIsView={setIsView}
                publicUser={publicUser}
                sortMode={sortMode}
                setSortMode={setSortMode}
              />
            </div>
          </Grid>
        </Grid>
      </ContentBox>
      {addUserModal &&
        (editData?.type == 'seeker' || editData?.type == 'provider' ? (
          <EditSeekerModal
            modal={addUserModal}
            toggle={() => setAddUserModal(!addUserModal)}
            data={editData}
            action={isView ? 'view' : 'edit'}
            otherFunction={getUserData}
          />
        ) : (
          <EditWorkerModal
            modal={addUserModal}
            toggle={() => setAddUserModal(!addUserModal)}
            data={editData}
            action={isView ? 'view' : 'edit'}
            otherFunction={getUserData}
          />
        ))}

      {alertModal && (
        <AlertDialog
          modal={alertModal}
          toggle={() => setAlertModal(!alertModal)}
          confirmFunc={() => blockUser(userId)}
          title={userStatus === 'block' ? 'Block User Confirmation' : 'Activate User Confirmation'}
          description={
            userStatus === 'block'
              ? 'Are you sure to block the user and its permissions?'
              : 'Are you sure to activate user and its permissions?'
          }
        />
      )}

      {alertDeleteModal && (
        <AlertDialog
          modal={alertDeleteModal}
          toggle={() => setAlertDeleteModal(!alertDeleteModal)}
          confirmFunc={() => deleteUser(userId)}
          title="Delete User Confirmation"
          description="Are you want to sure delete the user?"
        />
      )}

      {resetPassModal && (
        <AlertDialogModel
          modal={resetPassModal}
          toggle={() => setResetPassModal(!resetPassModal)}
          confirmFunc={() => publicUser(userId)}
          title={'Update Profile Confirmation'}
          description={`Are you sure to update the profile to ${
            editData?.is_public ? 'private mode' : 'public mode'
          }`}
        />
      )}
      <ToastContainer />
    </Fragment>
  );
};

export default ManageWorkerpool;
