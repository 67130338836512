import {
  Box,
  Icon,
  IconButton,
  Link,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import BlockIcon from "@mui/icons-material/Block";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import useAuth from "app/hooks/useAuth";
import { getRoleAndpermission } from "app/utils/utils";
import { useNavigate } from "react-router-dom";
const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0 } },
  },
  "& .MuiTableCell-root": {
    borderBottom: "1px solid #D1DFFF",
  },
  "& .MuiTableRow-root:nth-child(even)": {
    backgroundColor: "#f2f2f2", // Alternate row color
  },
}));

const StyledTableHead = styled(TableCell)(() => ({
  fontSize: "15px",
  fontWeight: 600,
  color: "white",
  backgroundColor: "rgba(39, 75, 175, 0.8)",
  "&:first-of-type": {
    borderTopLeftRadius: "15px", // Curved edge on the top-left corner
  },
  "&:last-of-type": {
    borderTopRightRadius: "15px", // Curved edge on the top-right corner
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  color: `#676A79`,
}));

const PaginationTable = ({
  data,
  alertModal,
  setAlertModal,
  alertDeleteModal,
  setAlertDeleteModal,
  addUserModal,
  setAddUserModal,
  resetPassModal,
  setResetPassModal,
  totalItems,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  setSkipData,
  setUserId,
  userStatus,
  setUserStatus,
  setSortData,
  sortData,
  setEditData,
  setIsView,
  publicUser,
  setEditUserModal,
  editUserModal,
}) => {
  const handleChangePage = (_, newPage) => {
    setSkipData(newPage * rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setSkipData(0);
  };
  const { roleAndPermission } = useAuth();

  const navigate = useNavigate()
  return (
    <Box>
      <Box width="100%" overflow="auto">
        <StyledTable
          style={{
            width: "1200px",
            overflowX: "auto",
            wordBreak: "break-word",
          }}
        >
          {" "}
          <TableHead>
            <TableRow>
              <StyledTableHead
                align="left"
                style={{ paddingLeft: "20px", width: "200px" }}
              >
                Name
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "200px" }}>
                Email
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "200px" }}>
                Phone
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "150px" }}>
                Background Status
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "150px" }}>
                Order No.
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "150px" }}>
                Remote Order No.
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "150px" }}>
                Membership
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "150px" }}>
                Total Amount
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "150px" }}>
                Created Date
                {sortData === "desc" ? (
                  <IconButton onClick={() => setSortData("asc")}>
                    <Icon sx={{ color: "white" }}>
                      <Tooltip title="Sort Date">
                        <ArrowDownwardIcon />
                      </Tooltip>
                    </Icon>
                  </IconButton>
                ) : (
                  <IconButton onClick={() => setSortData("desc")}>
                    <Icon sx={{ color: "white" }}>
                      <Tooltip title="Sort Date">
                        <ArrowUpwardIcon />
                      </Tooltip>
                    </Icon>
                  </IconButton>
                )}
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "150px" }}>
                Action
              </StyledTableHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((ele, index) => (
              <TableRow key={index}>
                <StyledTableCell align="left" style={{ paddingLeft: "20px" }}>
                  <Link
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      navigate(`/manage-user?id=${ele?.user?.id}`)
                    }
                  >
                    {ele?.user?.first_name + " " + ele?.user?.last_name}
                  </Link>
                </StyledTableCell>
                <StyledTableCell align="center">
                  {ele?.user?.email}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {ele?.user?.phone}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {ele?.backgroundStatus}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {ele?.orderNumber}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {ele?.remoteOrderNumber}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {ele?.user?.membership?.name || "Starter"}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {getRoleAndpermission(
                    roleAndPermission,
                    "Background Verification Management",
                    "payment"
                  )
                    ? ele?.payment?.totalAmount || "N/A"
                    : "--"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {moment(ele.createdAt).format("ll")}
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <IconButton
                    onClick={() => {
                      setAddUserModal(!addUserModal);
                      setIsView(true);
                      setEditData(ele);
                    }}
                    disabled={
                      !getRoleAndpermission(
                        roleAndPermission,
                        "Background Verification Management",
                        "view"
                      )
                    }
                  >
                    <Tooltip title="View">
                      <VisibilityIcon />
                    </Tooltip>
                  </IconButton>

                  <IconButton
                    onClick={() => {
                      setEditUserModal(!editUserModal);
                      setEditData(ele);
                    }}
                    disabled={
                      !getRoleAndpermission(
                        roleAndPermission,
                        "Background Verification Management",
                        "edit"
                      )
                    }
                  >
                    <Tooltip title="Edit">
                      <EditIcon />
                    </Tooltip>
                  </IconButton>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </Box>
      <TablePagination
        sx={{ px: 2 }}
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        count={totalItems}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ "aria-label": "Next Page" }}
        backIconButtonProps={{ "aria-label": "Previous Page" }}
      />
    </Box>
  );
};

export default PaginationTable;
