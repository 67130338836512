import {
  Box,
  Icon,
  IconButton,
  Link,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import BlockIcon from "@mui/icons-material/Block";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0 } },
  },
  "& .MuiTableCell-root": {
    borderBottom: "1px solid #D1DFFF",
  },
  "& .MuiTableRow-root:nth-child(even)": {
    backgroundColor: "#f2f2f2", // Alternate row color
  },
}));

const StyledTableHead = styled(TableCell)(() => ({
  fontSize: "15px",
  fontWeight: 600,
  color: "white",
  backgroundColor: "rgba(39, 75, 175, 0.8)",
  "&:first-of-type": {
    borderTopLeftRadius: "15px", // Curved edge on the top-left corner
  },
  "&:last-of-type": {
    borderTopRightRadius: "15px", // Curved edge on the top-right corner
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  color: `#676A79`,
}));

const PaginationTable = ({
  data,
  alertModal,
  setAlertModal,
  alertDeleteModal,
  setAlertDeleteModal,
  addUserModal,
  setAddUserModal,
  resetPassModal,
  setResetPassModal,
  totalItems,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  setSkipData,
  setUserId,
  userStatus,
  setUserStatus,
  setSortData,
  sortData,
  setEditData,
  setIsView,
  publicUser,
  sortMode,
  setSortMode,
}) => {
  const handleChangePage = (_, newPage) => {
    setSkipData(newPage * rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setSkipData(0);
  };

  const navigate = useNavigate()

  return (
    <Box>
      <Box width="100%" overflow="auto">
        <StyledTable
          style={{
            width: "1200px",
            overflowX: "auto",
            wordBreak: "break-word",
          }}
        >
          {" "}
          <TableHead>
            <TableRow>
              <StyledTableHead
                align="left"
                style={{ paddingLeft: "20px", width: "150px" }}
              >
                Name
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "120px" }}>
                Email
              </StyledTableHead>

              <StyledTableHead align="center" style={{ width: "120px" }}>
                Profession
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "120px" }}>
                Experience
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "120px" }}>
                Desire Country
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "150px" }}>
                Experience Region
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "120px" }}>
                Role
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "120px" }}>
                Work Type
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "120px" }}>
                NP Member
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "120px" }}>
                Membership
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "120px" }}>
                Created By
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "150px" }}>
                Created Date
                {sortData === "desc" ? (
                  <IconButton
                    onClick={() => {
                      setSortData("asc");
                      setSortMode(null);
                    }}
                  >
                    <Icon sx={{ color: "white" }}>
                      <Tooltip title="Sort Date">
                        <ArrowDownwardIcon />
                      </Tooltip>
                    </Icon>
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => {
                      setSortData("desc");
                      setSortMode(null);
                    }}
                  >
                    <Icon sx={{ color: "white" }}>
                      <Tooltip title="Sort Date">
                        <ArrowUpwardIcon />
                      </Tooltip>
                    </Icon>
                  </IconButton>
                )}
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "150px" }}>
                Public Mode
                {sortMode === "desc" ? (
                  <IconButton
                    onClick={() => {
                      setSortMode("asc");
                      setSortData(null);
                    }}
                  >
                    <Icon sx={{ color: "white" }}>
                      <Tooltip title="Sort Date">
                        <ArrowDownwardIcon />
                      </Tooltip>
                    </Icon>
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => {
                      setSortMode("desc");
                      setSortData(null);
                    }}
                  >
                    <Icon sx={{ color: "white" }}>
                      <Tooltip title="Sort Date">
                        <ArrowUpwardIcon />
                      </Tooltip>
                    </Icon>
                  </IconButton>
                )}
              </StyledTableHead>
              <StyledTableHead align="center" style={{ width: "250px" }}>
                Action
              </StyledTableHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((user, index) => (
              <TableRow key={index}>
                <StyledTableCell align="left" style={{ paddingLeft: "20px" }}>
                  <Link
                    sx={{ cursor: 'pointer' }}
                    onClick={() => navigate(`/manage-user?id=${user?.user_id}`)}
                  >
                    {user?.user?.first_name + " " + user?.user?.last_name}
                  </Link>
                </StyledTableCell>
                <StyledTableCell align="center">{user.email}</StyledTableCell>

                <StyledTableCell align="center">
                  {user?.profession.length
                    ? user?.profession.join(", ")
                    : "N/A"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {user?.experience
                    ? user?.experience > 1
                      ? user?.experience + " Years"
                      : user?.experience + " Year"
                    : "N/A"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {user?.desire_country ? user?.desire_country : "N/A"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {user?.experienceRegion
                    ? `${user?.experienceRegion?.name},${user?.experienceRegion?.region}`
                    : "N/A"}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {user?.type}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {user?.work_type ? user?.work_type : "N/A"}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {user?.np ? "Yes" : "No"}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {user.np ? user?.user?.membership?.name || "Starter" : "N/A"}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {user?.type !== "provider"
                    ? user?.created_by || "N/A"
                    : "N/A"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {moment(user.createdAt).format("ll")}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {user?.is_public_date
                    ? moment(user.is_public_date).format("ll")
                    : "N/A"}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  colSpan={2}
                  style={{ whiteSpace: "nowrap", textAlign: "left" }}
                >
                  <IconButton
                    onClick={() => {
                      setAddUserModal(!addUserModal);
                      setEditData(user);
                      setIsView(false);
                    }}
                  >
                    <EditIcon />
                  </IconButton>

                  <IconButton
                    onClick={() => {
                      setAddUserModal(!addUserModal);
                      setIsView(true);
                      setEditData(user);
                    }}
                  >
                    <Tooltip title="View">
                      <VisibilityIcon />
                    </Tooltip>
                  </IconButton>
                  {user.status === false ? (
                    <IconButton
                      onClick={() => {
                        setAlertModal(!alertModal);
                        setUserId(user.id);
                        setUserStatus("unblock");
                      }}
                    >
                      <Icon color="error">
                        <Tooltip title="Unblock User">
                          <BlockIcon />
                        </Tooltip>
                      </Icon>
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => {
                        setAlertModal(!alertModal);
                        setUserId(user.id);
                        setUserStatus("block");
                      }}
                    >
                      <Tooltip title="Block User">
                        <BlockIcon />
                      </Tooltip>
                    </IconButton>
                  )}

                  <IconButton
                    onClick={() => {
                      setAlertDeleteModal(!alertDeleteModal);
                      setUserId(user.id);
                    }}
                  >
                    <Tooltip title="Delete">
                      <DeleteIcon />
                    </Tooltip>
                  </IconButton>

                  {user?.type == "worker" && (
                    <Tooltip
                      title={!user?.is_public ? "Private Mode" : "Public Mode"}
                    >
                      <Switch
                        onChange={() => {
                          setUserId(user?.id);
                          setResetPassModal(true);
                          setEditData(user);
                        }}
                        checked={user?.is_public}
                        color="primary"
                        size="small"
                      />
                    </Tooltip>
                  )}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </Box>
      <TablePagination
        sx={{ px: 2 }}
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        count={totalItems}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ "aria-label": "Next Page" }}
        backIconButtonProps={{ "aria-label": "Previous Page" }}
      />
    </Box>
  );
};

export default PaginationTable;
