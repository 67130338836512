import { createContext, useEffect, useReducer } from "react";
import axios from "axios";
import { MatxLoading } from "app/components";
import { authApi } from "app/api/authAPI.js";
import jwtDecode from "jwt-decode";
import { call } from "app/utils/apiCall";

const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false,
  roleAndPermission: [],
};

const isValidToken = (accessToken) => {
  if (!accessToken) return false;

  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialised: true, user };
    }

    case "LOGIN": {
      // localStorage.setItem("user", JSON.stringify(action.payload));
      return { ...state, isAuthenticated: true, user: action.payload };
    }

    case "LOGOUT": {
      return { ...state, isAuthenticated: false, user: null };
    }
    case "ROLE_AND_PERMISSION": {
      return { ...state, roleAndPermission: action.payload };
    }
    // case 'REGISTER': {
    //   const { user } = action.payload;

    //   return { ...state, isAuthenticated: true, user };
    // }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => { },
  logout: () => { },
  forgot_password: () => { },
  reset_password: () => { },
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (email, password) => {
    const response = await authApi.login(email, password);
    console.log(response);
    if (response.success) {
      getAdminAndPermission();
      dispatch({ type: "LOGIN", payload: response.data });
    }
  };

  // const register = async (email, username, password) => {
  //   const response = await axios.post('/api/auth/register', { email, username, password });
  //   const { user } = response.data;

  //   dispatch({ type: 'REGISTER', payload: { user } });
  // };

  const forgot_password = async (email) => {
    await authApi.forgotPassword(email).then(
      (data) => {
        return Promise.resolve(data);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  const otp_password = async (data) => {
    return await authApi.otpPassword(data).then(
      (data) => {
        return Promise.resolve(data);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  const reset_password = async (data, token) => {
    await authApi.resetPassword(data, token).then(
      (data) => {
        return Promise.resolve(data);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  const logout = () => {
    localStorage.clear();
    dispatch({ type: "LOGOUT" });
  };

  const getAdminAndPermission = async () => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");
      if (accessToken) {
        const res = await call(
          "get",
          `api/v1/admin/get-admin`,
          {},
          null
        );
        console.log(res);

        if (res?.data?.data) {
          localStorage.setItem("user", JSON.stringify(res?.data?.data));
          dispatch({
            type: "INIT",
            payload: {
              isAuthenticated: true,
              user: res?.data?.data,
            },
          });
          dispatch({
            type: "ROLE_AND_PERMISSION",
            payload: res?.data?.permissions,
          });
        }
        else {
          dispatch({
            type: "INIT",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      }
      else {
        dispatch({
          type: "INIT",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      console.error(err, "error");
      dispatch({
        type: "INIT",
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  };

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const accessToken = window.localStorage.getItem("accessToken");
  //       let user = window.localStorage.getItem("user");
  //       // console.log(user);
  //       if (accessToken) {
  //         setSession(accessToken, user);

  //         user = JSON.parse(user);
  //         dispatch({
  //           type: "INIT",
  //           payload: {
  //             isAuthenticated: true,
  //             user,
  //           },
  //         });
  //       } else {
  //         console.log("false");
  //         dispatch({
  //           type: "INIT",
  //           payload: {
  //             isAuthenticated: false,
  //             user: null,
  //           },
  //         });
  //       }
  //     } catch (err) {
  //       console.error(err, "error");
  //       dispatch({
  //         type: "INIT",
  //         payload: {
  //           isAuthenticated: false,
  //           user: null,
  //         },
  //       });
  //     }
  //   })();
  // }, []);
  // const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    getAdminAndPermission()
  }, []);

  // const getRoleAndPermission = async (role_id) => {
  //   try {
  //     const res = await call(
  //       "get",
  //       `api/v1/admin/view-role/${role_id}`,
  //       {},
  //       null
  //     );
  //     dispatch({
  //       type: "ROLE_AND_PERMISSION",
  //       payload: res?.data?.data,
  //     });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // SHOW LOADER
  if (!state.isInitialised) return <MatxLoading />;

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        forgot_password,
        otp_password,
        reset_password,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
