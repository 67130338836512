import {
  Card,
  Grid,
  styled,
  useTheme,
  MenuItem,
  Select,
  Box,
  Button,
  InputAdornment,
  TextField,
  FormControl,
  InputLabel,
  Switch
} from '@mui/material';
import { Fragment } from 'react';
import PaginationTable from './components/userTable';
import { useEffect, useState } from 'react';
import { call } from '../../utils/apiCall';
import SearchIcon from '@mui/icons-material/Search';
import AlertDialog from './components/Alert';
import PasswordResetModal from './components/ResetPassword';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAuth from 'app/hooks/useAuth';
import { PrintExcel, getRoleAndpermission } from 'app/utils/utils';
import moment from 'moment';
import ViewReportModel from './components/viewReportModel';
import EditReportModel from './components/EditReportModel';
import { useSearchParams } from 'react-router-dom';

const ContentBox = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' }
}));

const CardHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  marginRight: '.5rem',
  textTransform: 'capitalize'
}));

const SubTitle = styled('span')(({ theme }) => ({
  fontSize: '1rem',
  color: theme.palette.text.secondary
}));

const H2 = styled('h2')(({ theme }) => ({
  fontSize: '24px',
  fontWeight: '600',
  color: '01052D',
  marginTop: '0px',
  marginBottom: '30px'
}));

const ManageBackgroundScreening = () => {
  const [searchParams] = useSearchParams();
  const { palette } = useTheme();
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addUserModal, setAddUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [alertDeleteModal, setAlertDeleteModal] = useState(false);
  const [resetPassModal, setResetPassModal] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [skipData, setSkipData] = useState(0);
  const [searchKey, setSearchKey] = useState();
  const [userId, setUserId] = useState();
  const [userStatus, setUserStatus] = useState();
  const [sortData, setSortData] = useState('desc');
  const [experienceRegionList, setExperienceRegionList] = useState([]);
  const [experience_region, setExperienceRegion] = useState(null);
  const [mode, setMode] = useState(null);
  const getExperienceRegion = () => {
    (async () => {
      try {
        const res = await call('get', 'api/v1/public/experience-region');
        setExperienceRegionList(res.data.data);
      } catch (err) {
        console.log(err);
      }
    })();
  };

  useEffect(() => {
    // getExperienceRegion();
    getUserData();
  }, [rowsPerPage, skipData, sortData, searchKey]);

  // useEffect(() => {
  //   getUserData();
  // }, [searchKey, skipData, rowsPerPage, sortData, experience_region, mode]);

  const getUserData = () => {
    (async () => {
      try {
        let query = { limit: rowsPerPage, skip: skipData, sort: sortData };
        if (searchKey) {
          query['search'] = searchKey;
        }

        if (searchParams?.get('user_id')) query['user_id'] = searchParams?.get('user_id');
        // if (experience_region) query['experience_region'] = experience_region;
        // if (mode) query['mode'] = mode;
        const res = await call('get', 'api/v1/admin/screening-request-list', query, null);
        setUsersData(res.data.data ? res.data.data : []);
        setTotalItems(res.data.totalItems ? res.data.totalItems : 0);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setUsersData([]);
      }
    })();
  };

  const publicUser = async (id) => {
    try {
      const res = await call('patch', `api/v1/admin/wp-public-profile/${id}`);
      setLoading(false);
      getUserData();
      setResetPassModal(!resetPassModal);
      setUserId('');
      toast.success('Profile Updated Successfully!', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } catch (err) {
      console.log(err);
    }
  };

  const { roleAndPermission } = useAuth();

  const [exportLoading, setExportLoading] = useState(false);

  const exportData = async () => {
    setExportLoading(true);
    try {
      const res = await call('get', 'api/v1/admin/screening-request-list', {
        limit: totalItems
      });
      setExportLoading(false);
      // const formattedData = [];
      // res?.data?.data.forEach((record) => {
      //   // Process Employment records
      //   (record.employment || []).forEach((employment, i) => {
      //     formattedData.push({
      //       Section: `Employment${i + 1}`,
      //       ID: employment.id || "",
      //     });
      //   });

      //   // Process Education records (if applicable)
      //   (record.education || []).forEach((education, i) => {
      //     formattedData.push({
      //       Section: `Education${i + 1}`,
      //       ID: education.id || "",
      //     });
      //   });

      //   // Process Basic Info
      //   const basicInfo = record.basicInfo?.info || {};
      //   formattedData.push({
      //     Section: "Basic Info",
      //     FirstName: basicInfo.first_name || "",
      //     LastName: basicInfo.last_name || "",
      //     Gender: record.basicInfo?.gender || "",
      //     DOB: basicInfo.dob ? moment(basicInfo.dob).format("YYYY-MM-DD") : "",
      //   });
      // });

      PrintExcel(
        res?.data?.data,
        `NP-ScreeningData-${moment(new Date()).format('YYYY-MM-DD')}.xlsx`
      );
    } catch (err) {
      console.log(err);
      setExportLoading(false);
    }
  };

  const [editData, setEditData] = useState();
  const [isView, setIsView] = useState(false);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
        // width: 250
      }
    }
  };
  return (
    <Fragment>
      <ContentBox className="analytics">
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <H2>Manage Background Screening</H2>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <div>
                <Button
                  disabled={
                    !getRoleAndpermission(
                      roleAndPermission,
                      'Background Verification Management',
                      'export'
                    )
                  }
                  variant="contained"
                  size="small"
                  onClick={exportData}
                  sx={{ px: 3, py: 0.9, backgroundColor: '#0432A3' }}
                >
                  {exportLoading ? 'Loading...' : 'Export'}
                </Button>
              </div>
              <TextField
                style={{ width: '500px' }}
                id="search"
                type="search"
                // label="Search"
                placeholder="Search by name, email, order no., remote order no."
                size="small"
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
                // sx={{ width: '50%' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <PaginationTable
                data={usersData}
                alertModal={alertModal}
                setAlertModal={setAlertModal}
                alertDeleteModal={alertDeleteModal}
                setAlertDeleteModal={setAlertDeleteModal}
                addUserModal={addUserModal}
                setAddUserModal={setAddUserModal}
                resetPassModal={resetPassModal}
                setResetPassModal={setResetPassModal}
                totalItems={totalItems}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setSkipData={setSkipData}
                setUserId={setUserId}
                userStatus={userStatus}
                setUserStatus={setUserStatus}
                sortData={sortData}
                setSortData={setSortData}
                setEditData={setEditData}
                setIsView={setIsView}
                publicUser={publicUser}
                setEditUserModal={setEditUserModal}
                editUserModal={editUserModal}
              />
            </div>
          </Grid>
        </Grid>
      </ContentBox>
      {addUserModal && (
        <ViewReportModel
          modal={addUserModal}
          toggle={() => setAddUserModal(!addUserModal)}
          data={editData}
          action={'view'}
        />
      )}

      {editUserModal && (
        <EditReportModel
          modal={editUserModal}
          toggle={() => setEditUserModal(!editUserModal)}
          data={editData}
          action={'view'}
          otherFunction={getUserData}
        />
      )}

      <ToastContainer />
    </Fragment>
  );
};

export default ManageBackgroundScreening;
