import * as React from 'react';

import { styled } from '@mui/material/styles';
import {
  useTheme,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import { call } from '../../../utils/apiCall';
import 'react-phone-input-2/lib/material.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build';
import { filterDropdown, tagDropdown } from '../ManageFaq';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
export default function AddUserModal(props) {
  const { modal, toggle, data, action } = props;
  const { palette } = useTheme();
  const textError = palette.error.main;
  const [question, setQuestion] = useState('');
  const [questionError, setQuestionError] = useState('');
  const [answerError, setAnswerError] = useState('');
  const [answer, setAnswer] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [linkText, setLinkText] = useState('');
  const [tag, setTag] = useState('');
  const [filter, setFilter] = useState('');
  const [titleError, setTitleError] = useState('');
  const [linkError, setLinkError] = useState('');
  const [linkTextError, setLinkTextError] = useState('');
  const [isPopular, setIsPopular] = useState(false);
  const [isHeading, setIsHeading] = useState(false);
  const [isLink, setIsLink] = useState(false);

  useEffect(() => {
    if (action == 'view' || action == 'edit') {
      setQuestion(data.question);
      setAnswer(data.answer);
      setTitle(data.heading);
      setIsPopular(data.is_popular);
      setIsHeading(data.heading ? true : false);
      setIsLink(data.link ? true : false);
      setLink(data.link || '');
      setLinkText(data.link_text || '');
      setTag(data.tag || '');
      setFilter(data.filter || '');
    }
  }, [data, action]);

  const handleValidation = () => {
    const websiteRegex =
      /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
    let formValid = true;

    if (question === '') {
      formValid = false;
      setQuestionError('Question is required.');
    } else if (question?.length < 10) {
      setQuestionError('Minimum 10 characaters required.');
      formValid = false;
    } else if (question?.length > 500) {
      setQuestionError('Please enter valid question upto 500 characaters.');
      formValid = false;
    }

    if (answer === '') {
      formValid = false;
      setAnswerError('Answer is required.');
    } else if (answer?.length < 200) {
      setAnswerError('Please enter valid answer minimum 200 characaters.');
      formValid = false;
    }
    if (isHeading)
      if (title === '' || !title) {
        formValid = false;
        setTitleError('Heading is required.');
      } else if (title?.length > 50) {
        setTitleError('Please enter valid heading upto 50 characaters.');
        formValid = false;
      }
    if (isLink)
      if (link === '' || !link) {
        formValid = false;
        setLinkError('Link is required.');
      } else if (!websiteRegex?.test(link)) {
        setLinkError('Please enter valid link.');
        formValid = false;
      }
    if (isLink)
      if (linkText === '' || !linkText) {
        formValid = false;
        setLinkTextError('Link Text is required.');
      } else if (linkText?.length > 50) {
        setLinkTextError('Please enter valid link text upto 50 characaters.');
        formValid = false;
      }
    return formValid;
  };

  const handleSubmit = async () => {
    if (handleValidation()) {
      const bodyData = {
        question,
        answer,
        heading: isHeading ? title : null,
        tag: tag || null,
        filter: filter || null,
        is_popular: isPopular ? 1 : 0,
        link: isLink ? link : null,
        link_text: isLink ? linkText : null
      };

      try {
        const res = await call('post', 'api/v1/admin/add-faq', null, bodyData);
        toggle();
        toast.success('FAQ Created Successfully!', {
          position: toast.POSITION.BOTTOM_LEFT
        });
      } catch (err) {
        console.log(err);
        setErrorMessage(err.response.data.message);
      }
    }
  };

  const handleEdit = async () => {
    if (handleValidation()) {
      const bodyData = {
        id: data.id,
        question,
        answer,
        heading: isHeading ? title : null,
        tag: tag || null,
        filter: filter || null,
        is_popular: isPopular ? 1 : 0,
        link: isLink ? link : null,
        link_text: isLink ? linkText : null
      };

      try {
        const res = await call('patch', 'api/v1/admin/edit-faq', null, bodyData);
        toggle();
        toast.success('FAQ Updated Successfully!', {
          position: toast.POSITION.BOTTOM_LEFT
        });
      } catch (err) {
        console.log(err);
        setErrorMessage(err.response.data.message);
      }
    }
  };

  return (
    <div>
      <BootstrapDialog maxWidth={'md'} aria-labelledby="customized-dialog-title" open={modal}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={toggle}>
          {action === 'add' ? 'Add FAQ' : action == 'view' ? 'View FAQ' : 'Edit FAQ'}
        </BootstrapDialogTitle>
        <DialogContent>
          <form style={{ padding: '30px', width: '600px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 20 }}>
              <TextField
                value={question}
                onClick={() => {
                  setQuestionError('');
                  setErrorMessage('');
                }}
                onChange={(e) => setQuestion(e.target.value)}
                helperText={questionError}
                error={Boolean(questionError)}
                multiline
                label="Question"
                minRows={4}
              ></TextField>

              {/* <TextField
                onClick={() => {
                  setAnswerError('');
                  setErrorMessage('');
                }}
                onChange={(e) => setAnswer(e.target.value)}
                helperText={answerError}
                error={Boolean(answerError)}
                value={answer}
                multiline
                label="Answer"
                margin="dense"
                minRows={9}
              ></TextField> */}

              <Grid item xs={12}>
                <CKEditor
                  editor={Editor}
                  data={answer}
                  config={{
                    placeholder: 'Enter the answer here...'
                  }}
                  style={{ border: answerError ? '1px solid #d9534f' : 'none' }}
                  onReady={(editor) => {
                    editor.editing.view.change((writer) => {
                      writer.setStyle('width', '100%', editor.editing.view.document.getRoot());
                      writer.setStyle(
                        'min-height',
                        '200px',
                        editor.editing.view.document.getRoot()
                      );
                    });
                  }}
                  onFocus={() => {
                    setAnswerError('');
                    setErrorMessage('');
                  }}
                  onChange={(event, editor) => {
                    setAnswer(editor.getData());
                  }}
                  onInit={(editor) => {
                    editor.editing.view.change((writer) => {
                      writer.setStyle('height', '200px', editor.editing.view.document.getRoot());
                    });
                  }}
                />
                {answerError && (
                  <Typography variant="label" component="p" color={'#d9534f'} mx={'20px'}>
                    {answerError}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} justifyContent={'space-around'}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isPopular}
                      onChange={(e) => setIsPopular(e.target.checked)}
                    />
                  }
                  label="Is Popular"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isHeading}
                      onChange={(e) => setIsHeading(e.target.checked)}
                    />
                  }
                  label="Is Heading"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={isLink} onChange={(e) => setIsLink(e.target.checked)} />
                  }
                  label="Is Link"
                />
              </Grid>

              {isHeading && (
                <TextField
                  onClick={() => {
                    setTitleError('');
                    setErrorMessage('');
                  }}
                  onChange={(e) => setTitle(e.target.value)}
                  helperText={titleError}
                  error={Boolean(titleError)}
                  value={title}
                  label="Heading"
                  margin="dense"
                ></TextField>
              )}

              {isLink && (
                <Grid item xs={12} display={'flex'} justifyContent={'space-between'} gap={'10px'}>
                  <TextField
                    style={{ width: '30%' }}
                    onClick={() => {
                      setLinkTextError('');
                      setErrorMessage('');
                    }}
                    onChange={(e) => setLinkText(e.target.value)}
                    helperText={linkTextError}
                    error={Boolean(linkTextError)}
                    value={linkText}
                    label="Link Text"
                    margin="dense"
                  ></TextField>
                  <TextField
                    style={{ width: '70%' }}
                    onClick={() => {
                      setLinkError('');
                      setErrorMessage('');
                    }}
                    onChange={(e) => setLink(e.target.value)}
                    helperText={linkError}
                    error={Boolean(linkError)}
                    value={link}
                    label="Link"
                    margin="dense"
                  ></TextField>
                </Grid>
              )}

              <FormControl variant="outlined" margin={'1'}>
                <InputLabel id="test-select-label">Tag</InputLabel>
                <Select
                  style={{ width: '100%' }}
                  variant="outlined"
                  value={tag}
                  onChange={(e) => {
                    setTag(e.target.value);
                  }}
                  labelId="test-select-label"
                  label={'Tag'}
                >
                  <MenuItem value={''}>{'Select Tag'}</MenuItem>
                  {tagDropdown.map((ele) => {
                    return <MenuItem value={ele.value}>{ele.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>

              <FormControl variant="outlined" margin={'1'}>
                <InputLabel id="test-select-label">Filter</InputLabel>
                <Select
                  style={{ width: '100%' }}
                  variant="outlined"
                  value={filter}
                  onChange={(e) => {
                    setFilter(e.target.value);
                  }}
                  labelId="test-select-label"
                  label={'Filter'}
                >
                  <MenuItem value={''}>{'Select Filter'}</MenuItem>
                  {filterDropdown.map((ele) => {
                    return <MenuItem value={ele.value}>{ele.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </div>
            {errorMessage && (
              <Typography
                style={{ marginTop: '1rem', textAlign: 'center' }}
                sx={{ color: textError }}
              >
                {errorMessage}
              </Typography>
            )}
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
              <Button
                // fullWidth
                variant="contained"
                color="primary"
                // onClick={handleFormSubmit}
                // loading={loading}
                sx={{ mt: 4, backgroundColor: '#0432A3' }}
                onClick={() => {
                  action == 'add' ? handleSubmit() : handleEdit();
                }}
              >
                {action === 'add' ? 'Create FAQ' : 'Update FAQ'}
              </Button>
              <Button
                // fullWidth
                variant="contained"
                color="primary"
                // onClick={handleFormSubmit}
                // loading={loading}
                sx={{ mt: 4, px: 4, backgroundColor: '#0432A3', ml: 2 }}
                onClick={toggle}
              >
                Close
              </Button>
            </div>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
