import {
  Card,
  Grid,
  styled,
  useTheme,
  MenuItem,
  Select,
  Box,
  Button,
  InputAdornment,
  TextField
} from '@mui/material';
import { Fragment } from 'react';
import PaginationTable from './components/tpTable';
import { useEffect, useState } from 'react';
import { call } from '../../utils/apiCall';
import SearchIcon from '@mui/icons-material/Search';
import AddTPModal from './components/AddTPModal';
import AlertDialog from './components/Alert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PrintExcel, getRoleAndpermission } from 'app/utils/utils';
import useAuth from 'app/hooks/useAuth';
import moment from 'moment';
import AdminView from './components/AdminView';
import MembersTable from './components/MembersTable';
import { useSearchParams } from 'react-router-dom';

const ContentBox = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' }
}));

const CardHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  marginRight: '.5rem',
  textTransform: 'capitalize'
}));

const SubTitle = styled('span')(({ theme }) => ({
  fontSize: '1rem',
  color: theme.palette.text.secondary
}));

const H2 = styled('h2')(({ theme }) => ({
  fontSize: '24px',
  fontWeight: '600',
  color: '01052D',
  marginTop: '0px',
  marginBottom: '30px'
}));

const ManageTalentpool = () => {
  const [searchParams] = useSearchParams();
  const { palette } = useTheme();
  const [tpData, setTPData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addTPModal, setAddTPModal] = useState(false);
  const [editTPData, setEditTPData] = useState(null);
  const [isView, setIsView] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [alertDeleteModal, setAlertDeleteModal] = useState(false);
  const [membersModal, setMembersModal] = useState(false);
  const [adminModal, setAdminModal] = useState(false);
  const [totalItems, setTotalItems] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [skipData, setSkipData] = useState(0);
  const [searchKey, setSearchKey] = useState();
  const [tpStatus, setTPStatus] = useState();
  const [sortData, setSortData] = useState('desc');
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    getTPData();
  }, [userId, searchKey, skipData, rowsPerPage, sortData]);

  useEffect(() => {
    const user_id = searchParams?.get('user_id');
    if (user_id) {
      setUserId(user_id);
    }
  }, [searchParams]);

  const getTPData = () => {
    (async () => {
      try {
        let query = {
          search: searchKey || undefined,
          user_id: userId || undefined,
          limit: rowsPerPage,
          skip: skipData,
          sort: sortData
        };
        const res = await call('get', 'api/v1/admin/talent-pool', query, null);
        setTPData(res.data.data ? res.data.data : []);
        setTotalItems(res.data.totalItems ? res.data.totalItems : 0);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setTPData([]);
      }
    })();
  };

  const deleteTP = async (id) => {
    try {
      await call('delete', `api/v1/admin/talent-pool/${id}`, null, null);
      setLoading(false);
      getTPData();
      setAlertDeleteModal(!alertDeleteModal);
      toast.success('Talent Pool Deleted Successfully!', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } catch (err) {
      console.log(err);
    }
  };

  const blockTP = async (id) => {
    let data = {
      status: tpStatus
    };
    try {
      await call('patch', `api/v1/admin/talent-pool/${id}`, null, data);
      setLoading(false);
      getTPData();
      setAlertModal(!alertModal);
      toast.success(
        tpStatus ? 'Talent Pool Activated Successfully!' : 'Talent Pool Deactivated Successfully!',
        {
          position: toast.POSITION.BOTTOM_LEFT
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const { roleAndPermission } = useAuth();

  const [exportLoading, setExportLoading] = useState(false);

  const exportData = async () => {
    setExportLoading(true);
    try {
      const res = await call('get', 'api/v1/admin/talent-pool', {
        limit: totalItems
      });
      setExportLoading(false);
      PrintExcel(
        res?.data?.data,
        `NP-TalentPoolData-${moment(new Date()).format('YYYY-MM-DD')}.xlsx`
      );
    } catch (err) {
      console.log(err);
      setExportLoading(false);
    }
  };

  return (
    <Fragment>
      <ContentBox className="analytics">
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <H2>TalentPool Management</H2>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', gap: '15px' }}>
                <Button
                  disabled={
                    !getRoleAndpermission(roleAndPermission, 'Talent Pool Management', 'add')
                  }
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setEditTPData(null);
                    setIsView(false);
                    setAddTPModal(!addTPModal);
                  }}
                  sx={{ px: 3, py: 0.9, backgroundColor: '#0432A3' }}
                >
                  + Add Talent Pool
                </Button>

                <Button
                  disabled={
                    !getRoleAndpermission(roleAndPermission, 'Talent Pool Management', 'export')
                  }
                  variant="contained"
                  size="small"
                  onClick={exportData}
                  sx={{ px: 3, py: 0.9, backgroundColor: '#0432A3' }}
                >
                  {exportLoading ? 'Loading...' : 'Export'}
                </Button>
              </Box>
              <div style={{ marginRight: '20px' }}>
                <TextField
                  id="search"
                  type="search"
                  // label="Search"
                  placeholder="Search..."
                  size="small"
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                  // sx={{ width: '50%' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <PaginationTable
                data={tpData}
                alertModal={alertModal}
                setAlertModal={setAlertModal}
                alertDeleteModal={alertDeleteModal}
                setAlertDeleteModal={setAlertDeleteModal}
                addTPModal={addTPModal}
                setAddTPModal={setAddTPModal}
                editTPData={editTPData}
                setEditTPData={setEditTPData}
                isView={isView}
                setIsView={setIsView}
                membersModal={membersModal}
                setMembersModal={setMembersModal}
                adminModal={adminModal}
                setAdminModal={setAdminModal}
                totalItems={totalItems}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setSkipData={setSkipData}
                tpStatus={tpStatus}
                setTPStatus={setTPStatus}
                sortData={sortData}
                setSortData={setSortData}
              />
            </div>
          </Grid>
        </Grid>
      </ContentBox>
      {addTPModal && (
        <AddTPModal
          modal={addTPModal}
          toggle={() => setAddTPModal(!addTPModal)}
          editTPData={editTPData}
          isView={isView}
          getTPData={() => getTPData()}
        />
      )}

      {alertModal && (
        <AlertDialog
          modal={alertModal}
          toggle={() => setAlertModal(!alertModal)}
          confirmFunc={() => blockTP(editTPData?.id)}
          title={tpStatus ? 'Activate TP Confirmation' : 'Deactivate TP Confirmation'}
          description={
            tpStatus
              ? 'Are you sure to activate the talent Pool and its permissions?'
              : 'Are you sure to deactivate talent Pool and its permissions?'
          }
        />
      )}

      {alertDeleteModal && (
        <AlertDialog
          modal={alertDeleteModal}
          toggle={() => setAlertDeleteModal(!alertDeleteModal)}
          confirmFunc={() => deleteTP(editTPData?.id)}
          title="Delete TP Confirmation"
          description="Are you want to sure delete the Talent Pool?"
        />
      )}

      {adminModal && (
        <AdminView
          modal={adminModal}
          toggle={() => setAdminModal(!adminModal)}
          tpData={editTPData}
        />
      )}

      {membersModal && (
        <MembersTable
          modal={membersModal}
          toggle={() => setMembersModal(!membersModal)}
          tpData={editTPData}
        />
      )}
      <ToastContainer />
    </Fragment>
  );
};

export default ManageTalentpool;
