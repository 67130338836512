import * as React from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import 'react-toastify/dist/ReactToastify.css';
import { STAGE_FRONTEND } from 'app/constants';
import { useEffect } from 'react';
import { useState } from 'react';
import { call } from 'app/utils/apiCall';
import { Chat, Mail } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const StyledTable = styled(Table)(() => ({
  whiteSpace: 'pre',
  marginBottom: '50px',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } }
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0 } }
  },
  '& .MuiTableCell-root': {
    borderBottom: '1px solid #D1DFFF'
  },
  '& .MuiTableRow-root:nth-child(even)': {
    backgroundColor: '#f2f2f2' // Alternate row color
  }
}));

const StyledTableHead = styled(TableCell)(() => ({
  fontSize: '15px',
  fontWeight: 600,
  color: 'white',
  backgroundColor: 'rgba(39, 75, 175, 0.8)',
  '&:first-of-type': {
    borderTopLeftRadius: '15px' // Curved edge on the top-left corner
  },
  '&:last-of-type': {
    borderTopRightRadius: '15px' // Curved edge on the top-right corner
  }
}));

const StyledTableCell = styled(TableCell)(() => ({
  color: `#676A79`
}));

export default function AdminView(props) {
  const { modal, toggle, tpData } = props;
  const superAdmin = { User: tpData?.User, user_id: tpData?.user_id, type: 'Super Admin' };
  const [loading, setLoading] = useState(false);
  const [adminData, setAdminData] = useState([superAdmin]);

  useEffect(() => {
    setLoading(true);
    call('get', `api/v1/admin/page-admin/${tpData?.Company?.id}`, null, null)
      .then((res) => {
        setLoading(false);
        setAdminData(res.data.data ? [superAdmin, ...res.data.data] : [superAdmin]);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const navigate = useNavigate();

  return (
    <div>
      <BootstrapDialog maxWidth={'md'} aria-labelledby="customized-dialog-title" open={modal}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={toggle}
          style={{ minWidth: '250px' }}
        >
          Admins
        </BootstrapDialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Box width="100%" overflow="auto">
              <StyledTable
                style={{ minWidth: '500px', overflowX: 'auto', wordBreak: 'break-word' }}
              >
                <TableHead>
                  <TableRow>
                    <StyledTableHead align="left" style={{ width: '75px', paddingLeft: '20px' }}>
                      ID
                    </StyledTableHead>
                    <StyledTableHead align="center" style={{ width: '100px' }}>
                      Name
                    </StyledTableHead>
                    <StyledTableHead align="center" style={{ width: '130px' }}>
                      Type
                    </StyledTableHead>
                    <StyledTableHead align="center" style={{ width: '130px' }}>
                      Profile Url
                    </StyledTableHead>
                    <StyledTableHead align="center" style={{ width: '75px' }}>
                      Action
                    </StyledTableHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adminData.map((admin, index) => (
                    <TableRow key={index}>
                      <StyledTableCell align="left" style={{ paddingLeft: '20px' }}>
                        {admin?.user_id}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Link
                          sx={{ cursor: 'pointer' }}
                          onClick={() => navigate(`/manage-user?id=${admin?.user_id}`)}
                        >
                          {admin?.User?.first_name
                            ? `${admin?.User?.first_name} ${admin?.User?.last_name}`
                            : admin?.User?.full_name || 'NP User'}
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell align="center">{admin?.type}</StyledTableCell>
                      <StyledTableCell align="center">
                        <Link
                          href={`${STAGE_FRONTEND}/profile/${
                            admin?.User?.profile_url || admin?.user_id
                          }`}
                          target="_blank"
                        >
                          {`${STAGE_FRONTEND}/profile/${
                            admin?.User?.profile_url || admin?.user_id
                          }`}
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <IconButton>
                          <Tooltip title="Mail">
                            <Link href={`mailto:${admin?.User?.email}`}>
                              <Mail />
                            </Link>
                          </Tooltip>
                        </IconButton>
                        <IconButton>
                          <Tooltip title="Message">
                            <Link href={`${STAGE_FRONTEND}/message`} target="_blank">
                              <Chat />
                            </Link>
                          </Tooltip>
                        </IconButton>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </Box>
          )}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
