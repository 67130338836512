import * as React from 'react';
import { API_URL } from 'app/constants';
import axios from 'axios';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import {
  useTheme,
  TextField,
  InputAdornment,
  Grid,
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { call } from '../../../utils/apiCall';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageVerify from 'app/utils/imageVerify';
import {
  PhoneNumberUtil,
  // using PNF alias to follow along with documentaion
  PhoneNumberFormat as PNF
} from 'google-libphonenumber';
import moment from 'moment';
// import infoIcon from '../../assets/img/infoIcon.png';
import { useEffect } from 'react';
import useAuth from 'app/hooks/useAuth';
import { getRoleAndpermission } from 'app/utils/utils';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const styles = styled(TextField)({
  '*.Mui-focused': {
    borderColor: 'transparent',
    outline: 'none'
  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
      // width: 250
    }
  }
};

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, textTransform: 'capitalize' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function ViewReportModel(props) {
  const { modal, toggle, data, action, otherFunction } = props;
  const { palette } = useTheme();
  const [bgData, setBgData] = useState();

  const { roleAndPermission } = useAuth();

  const getUserData = () => {
    (async () => {
      try {
        let query = { id: data?.id };

        const res = await call('get', 'api/v1/admin/screening-request-list', query, null);
        setBgData(res.data.data[0]);
      } catch (err) {
        console.log(err);
      }
    })();
  };

  // console.log(bgData);

  useEffect(() => {
    getUserData();
  }, []);

  const status = (status) => {
    if (status == 'pending')
      return (
        <p
          className="p-1 rounded"
          style={{
            background: '#FE980033',
            color: '#FE9800',
            width: 'fit-content',
            padding: '5px',
            borderRadius: '5px'
          }}
        >
          Pending
        </p>
      );

    if (status == 'Verified')
      return (
        <p
          className="p-1 rounded"
          style={{
            background: '#169B0033',
            color: '#169B00',
            width: 'fit-content',
            padding: '5px',
            borderRadius: '5px'
          }}
        >
          Verified
        </p>
      );
    if (status == 'Discrepancy')
      return (
        <p
          className="p-1 rounded"
          style={{
            background: '#FE1E0033',
            color: '#FE1E00',
            width: 'fit-content',
            padding: '5px',
            borderRadius: '5px'
          }}
        >
          Discrepancy
        </p>
      );
  };

  return (
    <div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={modal}
        fullWidth={true}
        maxWidth={'lg'}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={toggle}>
          View Report
        </BootstrapDialogTitle>
        <DialogContent>
          <Box sx={{ padding: 3 }}>
            <Typography variant="h5" gutterBottom>
              REPORT FOR - {bgData?.user?.first_name} {bgData?.user?.last_name}
            </Typography>

            <Grid container spacing={3}>
              {/* Subject Information */}
              <Grid item xs={12} md={8}>
                <Card>
                  <CardContent sx={{ border: '1px solid #d1dfff' }}>
                    <Table sx={{ mb: 3 }}>
                      <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                        SUBJECT INFORMATION
                      </Typography>

                      <Typography variant="p" gutterBottom sx={{ fontWeight: 600 }}>
                        DEMOGRAPHICS
                      </Typography>
                      <TableBody>
                        <TableRow>
                          <TableCell>Name (LFM)</TableCell>
                          <TableCell>
                            {bgData?.basicInfo?.info?.first_name},{' '}
                            {bgData?.basicInfo?.info?.last_name}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Address</TableCell>
                          <TableCell>
                            {bgData?.basicInfo?.address || '-'} , {bgData?.basicInfo?.zip}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Phone</TableCell>
                          <TableCell>{bgData?.basicInfo?.applicant_phone || '-'}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Email</TableCell>
                          <TableCell>{bgData?.basicInfo?.applicant_email || '-'}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Ssn</TableCell>
                          <TableCell>{bgData?.basicInfo?.ssn || '-'}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Nationality</TableCell>
                          <TableCell>{bgData?.basicInfo?.nationality || '-'}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Country Of Residence</TableCell>
                          <TableCell>{bgData?.basicInfo?.residence || '-'}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Date of Birth</TableCell>
                          <TableCell>
                            {moment(bgData?.basicInfo?.dob).format('DD/MM/YYYY') || '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Gender</TableCell>
                          <TableCell>{bgData?.basicInfo?.gender || '-'}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Typography variant="p" gutterBottom sx={{ fontWeight: 600 }}>
                      lOCAL AND NATIONAL IDENTIFICATION
                    </Typography>
                    {bgData?.employment?.map((ele, i) => {
                      return (
                        <Table sx={{ mb: 3, mt: 3 }}>
                          <Typography variant="p" gutterBottom sx={{ fontWeight: 600 }}>
                            EMPLOYMENT #{i + 1}
                          </Typography>
                          <TableBody>
                            <TableRow>
                              <TableCell>Company Name</TableCell>
                              <TableCell>{ele?.company_name || '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Contact Name</TableCell>
                              <TableCell>{ele?.contact_name || '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Phone</TableCell>
                              <TableCell>{ele?.phone || '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Email</TableCell>
                              <TableCell>{ele?.email || '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Your Title/ Position with Employer</TableCell>
                              <TableCell>{ele?.position || '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Start Date</TableCell>
                              <TableCell>
                                {moment(ele?.start_date).format('DD/MM/YYYY') || '-'}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>End Date</TableCell>
                              <TableCell>
                                {ele?.end_date ? moment(ele?.end_date).format('DD/MM/YYYY') : '-'}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Is Current</TableCell>
                              <TableCell>{ele?.is_current ? 'Y' : 'N'}</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>Screening Status</TableCell>
                              <TableCell>{status(ele?.screening_status || 'pending')}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      );
                    })}

                    {bgData?.education?.map((ele, i) => {
                      return (
                        <Table sx={{ mb: 3, mt: 3 }}>
                          <Typography variant="p" gutterBottom sx={{ fontWeight: 600 }}>
                            EDUCATION #{i + 1}
                          </Typography>
                          <TableBody>
                            <TableRow>
                              <TableCell>School Name</TableCell>
                              <TableCell>{ele?.school_name || '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Campus</TableCell>
                              <TableCell>{ele?.campus || '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Phone</TableCell>
                              <TableCell>{ele?.phone || '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Email</TableCell>
                              <TableCell>{ele?.email || '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Degree</TableCell>
                              <TableCell>{ele?.degree || '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Start Date</TableCell>
                              <TableCell>
                                {moment(ele?.start_date).format('DD/MM/YYYY') || '-'}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>End Date</TableCell>
                              <TableCell>
                                {ele?.end_date ? moment(ele?.end_date).format('DD/MM/YYYY') : '-'}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Major</TableCell>
                              <TableCell>{ele?.major || '-'}</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>Screening Status</TableCell>
                              <TableCell>{status(ele?.screening_status || 'pending')}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      );
                    })}
                  </CardContent>
                </Card>
              </Grid>

              {/* Payment Information */}
              <Grid item xs={12} md={4}>
                {getRoleAndpermission(
                  roleAndPermission,
                  'Background Verification Management',
                  'payment'
                ) ? (
                  <Card sx={{ mb: 3 }}>
                    <CardContent sx={{ border: '1px solid #d1dfff' }}>
                      <Typography variant="h6" gutterBottom>
                        PAYMENT INFORMATION
                      </Typography>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>Employment check</TableCell>
                            <TableCell>${bgData?.employment?.length * 54 || 0}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Education Check</TableCell>
                            <TableCell>${bgData?.education?.length * 54 || 0}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Criminal Check</TableCell>
                            <TableCell>
                              ${bgData?.criminal && Object.keys(bgData?.criminal).length ? 54 : 0}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Tax</TableCell>
                            <TableCell>18%</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Discount</TableCell>
                            <TableCell>-20%</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Total payment</TableCell>
                            <TableCell>${bgData?.payment?.totalAmount}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Payment date</TableCell>
                            <TableCell>{moment(bgData?.createdAt).format('DD/MM/YYYY')}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>
                ) : (
                  <></>
                )}

                <Card>
                  <CardContent sx={{ border: '1px solid #d1dfff' }}>
                    <Typography variant="h6" gutterBottom>
                      DOCUMENTS
                    </Typography>
                    <ul>
                      {bgData?.basicInfo?.documents_name?.map((e) => {
                        return <li>{e}</li>;
                      })}

                      {bgData?.employment?.map((ele) => {
                        return ele?.documents_name?.map((e) => {
                          return <li>{e}</li>;
                        });
                      })}

                      {bgData?.education?.map((ele) => {
                        return ele?.documents_name?.map((e) => {
                          return <li>{e}</li>;
                        });
                      })}
                    </ul>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={4}></Grid>

              {/* Order Information */}
              <Grid item xs={12}>
                <Card>
                  <CardContent sx={{ border: '1px solid #d1dfff' }}>
                    <Typography variant="h6" gutterBottom>
                      ORDER INFORMATION
                    </Typography>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>Order Number</TableCell>
                          <TableCell>{bgData?.orderNumber || '-'}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Remote Order Number</TableCell>
                          <TableCell>{bgData?.remoteOrderNumber || '-'}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Order Date</TableCell>
                          <TableCell>
                            {moment(bgData?.createdAt).format('DD/MM/YYYY') || '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Summary</TableCell>
                          <TableCell>
                            {(bgData?.employment?.length > 0 ||
                              bgData?.education?.length > 0 ||
                              bgData?.criminal?.residence) &&
                              `Request for ${
                                (bgData?.employment?.length > 0 && 'Employment') || ''
                              }${(bgData?.education?.length > 0 && ', Educational') || ''}${
                                (bgData?.criminal?.residence && ' and Background') || ''
                              } verification`}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
