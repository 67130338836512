import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useTheme, TextField, Grid, Autocomplete } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useState, useEffect } from 'react';
import { call } from '../../../utils/apiCall';
import { toast } from 'react-toastify';
import axios from 'axios';
import { UPLOAD_API_URL } from 'app/constants';
import { getRoleAndpermission } from 'app/utils/utils';
import useAuth from 'app/hooks/useAuth';
import profile from '../../../utils/img/no-profile-picture-icon.svg';
import { ColorPicker } from 'material-ui-color';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
      // width: 250
    }
  }
};

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function AddTPModal(props) {
  const { modal, toggle, editTPData, getTPData, isView } = props;
  const { palette } = useTheme();
  const textError = palette.error.main;

  const [nameError, setNameError] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorCompany, setErrorCompany] = useState('');
  const [companyData, setCompanyData] = useState([]);
  const [searchCompany, setSearchCompany] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const res = await call(
          'get',
          'api/v1/admin/company',
          searchCompany ? { search: searchCompany } : null,
          null
        );
        if (res?.data?.success) {
          setCompanyData(res?.data?.data);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [searchCompany]);

  const [tpData, setTPData] = useState(
    editTPData
      ? {
          company_id: editTPData?.Company?.id,
          company_name: editTPData.company_name,
          email: editTPData.email,
          icon: editTPData.icon,
          theme: editTPData.theme,
          background_img: editTPData.background_img,
          privacy_policy: editTPData.privacy_policy,
          cookie_policy: editTPData.cookie_policy,
          terms: editTPData.terms
        }
      : {
          company_id: '',
          company_name: '',
          email: '',
          icon: '',
          theme: '#F68A18',
          background_img: '',
          privacy_policy: '',
          cookie_policy: '',
          terms: '',
          user_id: null,
          user_email: ''
        }
  );

  const handleImageChange = async (e) => {
    if (e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png') {
      try {
        let formData = new FormData();
        formData.append('image', e.target.files[0]);
        const res = await axios.post(`${UPLOAD_API_URL}api/v1/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Accept-Language': 'en'
          }
        });

        if (res.data.success === true) {
          let myImage = res.data.urlsArray[0];
          setTPData({ ...tpData, icon: myImage });
        }
      } catch (err) {
        console.log(err);
        setErrorMessage(err.response.data.message);
      }
    }
  };

  const handleBannerChange = async (e) => {
    if (e.target.files[0].type == 'image/jpeg' || e.target.files[0].type == 'image/png') {
      try {
        let formData = new FormData();
        formData.append('image', e.target.files[0]);
        const res = await axios.post(`${UPLOAD_API_URL}api/v1/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Accept-Language': 'en'
          }
        });

        if (res.data.success === true) {
          let myImage = res.data.urlsArray[0];
          setTPData({ ...tpData, background_img: myImage });
        }
      } catch (err) {
        console.log(err);
        setErrorMessage(err.response.data.message);
      }
    }
  };

  const validation = (type) => {
    let emailvalid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let formIsValid = true;
    if (!tpData.company_name) {
      setNameError('Please enter company name');
      formIsValid = false;
    } else {
      setNameError('');
    }

    if (!tpData?.email) {
      setErrorEmail('Please enter your email address.');
      formIsValid = false;
    } else if (!emailvalid?.test(tpData.email)) {
      setErrorEmail('Please enter a valid email address.');
      formIsValid = false;
    } else {
      setErrorEmail('');
    }

    if (!tpData?.company_id) {
      setErrorCompany('Please select the company.');
      formIsValid = false;
    } else {
      setErrorCompany('');
    }

    return formIsValid;
  };

  const handleSubmit = async () => {
    if (validation()) {
      try {
        if (editTPData?.id)
          await call('patch', `api/v1/admin/talent-pool/${editTPData.id}`, null, {
            company_name: tpData.company_name,
            email: tpData.email,
            icon: tpData.icon,
            theme: tpData.theme,
            background_img: tpData.background_img,
            privacy_policy: tpData.privacy_policy,
            cookie_policy: tpData.cookie_policy,
            terms: tpData.terms
          });
        else
          await call('post', `api/v1/admin/talent-pool`, null, {
            company_id: tpData.company_id,
            user_id: tpData.user_id,
            super_admin_email: tpData.user_email,
            company_name: tpData.company_name,
            email: tpData.email,
            icon: tpData.icon,
            theme: tpData.theme,
            background_img: tpData.background_img,
            privacy_policy: tpData.privacy_policy,
            cookie_policy: tpData.cookie_policy,
            terms: tpData.terms
          });

        getTPData();
        toast.success(
          editTPData ? 'Talent Pool Updated Successfully.' : 'Talent Pool Added Successfully.',
          {
            position: toast.POSITION.BOTTOM_LEFT
          }
        );
        toggle();
        // setLoading(false);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const { roleAndPermission } = useAuth();

  return (
    <div>
      <BootstrapDialog
        fullWidth={true}
        maxWidth={'sm'}
        sx={{ maxHeight: '90vh' }}
        aria-labelledby="customized-dialog-title"
        open={modal}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={toggle}>
          {isView ? 'View Talent Pool' : editTPData ? 'Edit Talent Pool' : 'Add Talent Pool'}
        </BootstrapDialogTitle>
        <DialogContent>
          <form>
            <Autocomplete
              fullWidth
              disablePortal
              disabled={isView || editTPData ? true : false}
              size="small"
              name="Company"
              label="Company"
              id="combo-box-demo"
              options={companyData}
              // renderOption={(props, data) => (
              //   <>
              //     <div class="fake-input">
              //       {data?.icon ? (
              //         <img
              //           src={data?.icon}
              //           alt="icon"
              //           className="image-fit mr-10"
              //           style={{ height: '36px', width: '36px' }}
              //         />
              //       ) : (
              //         <Business />
              //       )}
              //       <span>{data?.name}</span>
              //     </div>
              //   </>
              // )}
              getOptionLabel={(data) => data?.name}
              onChange={(event, newValue) => {
                setTPData({
                  ...tpData,
                  company_id: newValue?.id || null,
                  user_id: newValue?.created_by || null,
                  user_email: newValue?.Created_by?.email || null
                });
              }}
              onInputChange={(event, newInputValue) => {
                setSearchCompany(newInputValue);
              }}
              sx={{ mb: 3, mt: 2 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={editTPData?.Company?.name || 'Company'}
                  error={Boolean(errorCompany)}
                  helperText={errorCompany}
                />
              )}
            />
            <TextField
              fullWidth
              size="small"
              disabled={isView}
              type="text"
              name="TP Name"
              label="Talent Pool Name"
              variant="outlined"
              // onBlur={handleBlur}
              value={tpData.company_name}
              onClick={() => setNameError('')}
              onChange={(e) => {
                setTPData({ ...tpData, company_name: e.target.value });
              }}
              helperText={nameError}
              error={Boolean(nameError)}
              sx={{ mb: 3 }}
              inputProps={{ maxLength: 250 }}
            />

            <TextField
              fullWidth
              size="small"
              type="email"
              disabled={isView}
              name="email"
              label="Email"
              variant="outlined"
              // onBlur={handleBlur}
              value={tpData.email}
              onClick={() => setErrorEmail('')}
              onChange={(e) => {
                setTPData({ ...tpData, email: e.target.value });
              }}
              helperText={errorEmail}
              error={Boolean(errorEmail)}
              sx={{ mb: 3 }}
              inputProps={{ maxLength: 60 }}
            />
            <Grid container spacing={3}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography variant="label" mt={2} component="h4" align="center">
                  Company Logo
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '10px 0',
                    marginBottom: '15px',
                    gap: '20px'
                  }}
                >
                  <img
                    src={tpData?.icon && tpData?.icon !== '' ? tpData?.icon : profile}
                    width={'100px'}
                    height={'100px'}
                    alt=""
                  />
                  {!isView && (
                    <Button
                      component="label"
                      disabled={
                        !getRoleAndpermission(
                          roleAndPermission,
                          'Talent Pool Management',
                          editTPData ? 'edit' : 'add'
                        )
                      }
                    >
                      Upload
                      <input
                        type="file"
                        hidden
                        accept="image/png, image/jpeg"
                        onChange={(e) => handleImageChange(e)}
                      ></input>
                    </Button>
                  )}
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography variant="label" mt={2} component="h4" align="center">
                  Banner Image
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    padding: '10px 0',
                    marginBottom: '15px',
                    gap: '20px'
                  }}
                >
                  <img
                    src={
                      tpData?.background_img && tpData?.background_img !== ''
                        ? tpData?.background_img
                        : profile
                    }
                    width={'100px'}
                    height={'100px'}
                    alt=""
                  />
                  {!isView && (
                    <Button
                      component="label"
                      disabled={
                        !getRoleAndpermission(
                          roleAndPermission,
                          'Talent Pool Management',
                          editTPData ? 'edit' : 'add'
                        )
                      }
                    >
                      Upload
                      <input
                        type="file"
                        hidden
                        accept="image/png, image/jpeg"
                        onChange={(e) => handleBannerChange(e)}
                      ></input>
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>

            <div
              style={{
                marginBottom: '15px'
              }}
            >
              <Typography variant="label" component="h4" mb={'10px'}>
                Branding Color
              </Typography>
              <ColorPicker
                style={{ alignItems: 'center' }}
                value={tpData?.theme}
                disableTextfield
                onChange={(color) => {
                  if (!isView && color?.hex) setTPData({ ...tpData, theme: `#${color.hex}` });
                }}
              />
            </div>

            <Grid item xs={12} mb={'20px'}>
              <Typography variant="label" component="h4" mb={'10px'}>
                Privacy Policy
              </Typography>
              <CKEditor
                editor={Editor}
                config={{
                  toolbar: ['bold', 'italic', 'underline']
                }}
                disabled={isView}
                data={tpData?.privacy_policy}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle('width', '100%', editor.editing.view.document.getRoot());
                    writer.setStyle('min-height', '100px', editor.editing.view.document.getRoot());
                  });
                }}
                onChange={(event, editor) => {
                  setTPData({ ...tpData, privacy_policy: editor.getData() });
                }}
                onInit={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle('height', '200px', editor.editing.view.document.getRoot());
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} mb={'20px'}>
              <Typography variant="label" component="h4" mb={'10px'}>
                Cookies Policy
              </Typography>
              <CKEditor
                editor={Editor}
                config={{
                  toolbar: ['bold', 'italic', 'underline']
                }}
                disabled={isView}
                data={tpData?.cookie_policy}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle('width', '100%', editor.editing.view.document.getRoot());
                    writer.setStyle('min-height', '100px', editor.editing.view.document.getRoot());
                  });
                }}
                onChange={(event, editor) => {
                  setTPData({ ...tpData, cookie_policy: editor.getData() });
                }}
                onInit={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle('height', '200px', editor.editing.view.document.getRoot());
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} mb={'20px'}>
              <Typography variant="label" component="h4" mb={'10px'}>
                Terms
              </Typography>
              <CKEditor
                editor={Editor}
                config={{
                  toolbar: ['bold', 'italic', 'underline']
                }}
                disabled={isView}
                data={tpData?.terms}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle('width', '100%', editor.editing.view.document.getRoot());
                    writer.setStyle('min-height', '100px', editor.editing.view.document.getRoot());
                  });
                }}
                onChange={(event, editor) => {
                  setTPData({ ...tpData, terms: editor.getData() });
                }}
                onInit={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle('height', '200px', editor.editing.view.document.getRoot());
                  });
                }}
              />
            </Grid>

            {!isView && (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                // onClick={handleFormSubmit}
                // loading={loading}
                sx={{ mb: 3, backgroundColor: '#0432A3' }}
                onClick={handleSubmit}
              >
                {editTPData ? 'Update Talent Pool' : 'Create Talent Pool'}
              </Button>
            )}
            {errorMessage && (
              <Typography style={{ marginTop: '1rem' }} sx={{ color: textError }}>
                {errorMessage}
              </Typography>
            )}
          </form>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
