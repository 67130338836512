import * as React from "react";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import { useTheme, TextField, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { call } from "../../utils/apiCall";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import useAuth from "app/hooks/useAuth";
import { getRoleAndpermission } from "app/utils/utils";
import ImageVerify from "app/utils/imageVerify";
import { UPLOAD_API_URL } from "app/constants";
import axios from "axios";

const H2 = styled("h2")(({ theme }) => ({
  fontSize: "24px",
  fontWeight: "600",
  color: "01052D",
  marginTop: "0px",
  marginBottom: "30px",
}));

export default function EditFooter(props) {
  const { toggle } = props;
  const { palette } = useTheme();
  const textError = palette.error.main;
  const params = useParams();
  const [pageData, setPageData] = useState([]);
  const [logo, setLogo] = useState("");
  const [currentText, setCurrentText] = useState("");
  const [title, setTitle] = useState("");
  const [currentTextError, setCurrentTextError] = useState("");
  const [selectedKey, setSelectedKey] = useState("");
  const [idsToDelete, setIdsToDelete] = useState([]);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    let newData = pageData.filter((item) => !item.id); // New data without `id`
    let bodyData = {
      mainTitle: title,
      forParam: "footer",
      mainId: parseInt(params.id),
      idsToDelete: idsToDelete,
      newData: newData,
      media: logo
    };

    try {
      const res = await call(
        "patch",
        "api/v1/admin/edit-content",
        {},
        bodyData
      );
      if (res.data.success === true) {
        navigate("/footer-management");
        toast.success("Footer updated successfully.", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNewData = () => {
    let formValid = true;

    if (selectedKey === "") {
      setCurrentTextError("Key is required.");
      formValid = false;
    }

    if (currentText === "") {
      setCurrentTextError("Title is required.");
      formValid = false;
    }

    if (formValid) {
      let newItem = {
        body: null,
        key: selectedKey,
        title: currentText,
        link: null,
        subtitle: null,
        media: null,
        router_link: selectedKey,
      };

      setPageData((prevData) => [...prevData, newItem]);
      setCurrentText("");
      setSelectedKey("");
    }
  };

  const handleEditChange = (index, field, value) => {
    setPageData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index][field] = value;
      return updatedData;
    });
  };

  const removeOldData = (oldId, index) => {
    setIdsToDelete((prevData) => [...prevData, oldId]);

    setPageData((prevData) => {
      const updatedData = [...prevData];
      updatedData.splice(index, 1);
      return updatedData;
    });
  };

  const removeNewData = (index) => {
    setPageData((prevData) => {
      const updatedData = [...prevData];
      updatedData.splice(index, 1);
      return updatedData;
    });
  };

  const getPageData = async () => {
    try {
      const res = await call(
        "get",
        "api/v1/get-static-pages",
        { title: params.key },
        null
      );
      if (res.data.success) {
        setPageData(res.data.data.body);
        setLogo(res.data.data.media);
        setTitle(res.data.data.title);
      }
    } catch (err) {
      console.log(err);
      setPageData([]);
      setLogo("")
    }
  };

  const handleImageChange = async (e) => {
    if (
      e.target.files[0].type == "image/jpeg" ||
      e.target.files[0].type == "image/png"
    ) {
      try {
        let formData = new FormData();
        formData.append("image", e.target.files[0]);
        const res = await axios.post(`${UPLOAD_API_URL}api/v1/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Accept-Language": "en",
          },
        });
        if (res.data.success === true) {
          let myImage = res.data.urlsArray[0].split(".com/")[1];
          const newImage = UPLOAD_API_URL + myImage;
          setLogo(newImage);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    getPageData();
  }, []);

  const { roleAndPermission } = useAuth();

  return (
    <form className="form-container" style={{ padding: "30px" }}>
      <h3 className="breadcumbTitle">
        <span
          className="bread-link"
          onClick={() => navigate("/footer-management")}
          onMouseEnter={(e) => (e.target.style.color = "#2563a4")}
          onMouseLeave={(e) => (e.target.style.color = "black")}
        >
          Footer Management {">"}
        </span>
        <span> Edit Footer </span>
      </h3>
      <Grid container spacing={3}>
        <Grid item width={"100%"}>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <h2 className="section-header">Title </h2>
            <span>(Use YYYY for current year)</span>
          </div>
          <TextField
            fullWidth
            size="small"
            label="Title"
            variant="outlined"
            value={title}
            onChange={(e) =>
              setTitle(e.target.value)
            }
            className="text-field"
            sx={{ mb: 3, mt: 2 }}
          />
        </Grid>
        <Grid item width={"100%"}>
          <h2 className="section-header">NP Logo</h2>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ImageVerify
              imageURL={logo || null}
              width={"100%"}
              height={"100px"}
            ></ImageVerify>
            <Button component="label">
              Upload{" "}
              <input
                type="file"
                hidden
                accept="image/png, image/jpeg"
                onChange={(e) => handleImageChange(e)}
              ></input>
            </Button>
          </div>
        </Grid>
        <Grid item>
          <h2 className="section-header">Links</h2>
          <Grid container spacing={1}>
            {pageData.length > 0 &&
              pageData.map((ele, index) => (
                <div
                  className="link-row"
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Grid item lg={5} md={6} sm={6} xs={12}>
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%" }}
                      className="form-control"
                      margin={"1"}
                    >
                      <InputLabel>Key</InputLabel>
                      <Select
                        style={{ width: "100%" }}
                        value={ele.key}
                        onChange={(e) =>
                          handleEditChange(index, "key", e.target.value)
                        }
                      >
                        <MenuItem value={"about"}>about</MenuItem>
                        <MenuItem value={"legal"}>legal</MenuItem>
                        <MenuItem value={"community"}>community</MenuItem>
                        <MenuItem value={"help"}>help</MenuItem>
                        <MenuItem value={"membership"}>membership</MenuItem>
                        <MenuItem value={"blog"}>blog</MenuItem>
                        <MenuItem value={"mobile"}>mobile</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={5} md={6} sm={6} xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Title"
                      variant="outlined"
                      value={ele.title}
                      onChange={(e) =>
                        handleEditChange(index, "title", e.target.value)
                      }
                      className="text-field"
                      sx={{ mb: 3, mt: 2 }}
                    />
                  </Grid>
                  <Grid item lg={1} md={6} sm={6} xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        ele.id
                          ? removeOldData(ele.id, index)
                          : removeNewData(index)
                      }
                      className="remove-button"
                      sx={{ backgroundColor: "#0432A3" }}
                      disabled={
                        !getRoleAndpermission(
                          roleAndPermission,
                          "Footer Management",
                          "edit"
                        )
                      }
                    >
                      -
                    </Button>
                  </Grid>
                </div>
              ))}
            <div
              className="new-link-row"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Grid item lg={5} md={6} sm={6} xs={12}>
                <FormControl
                  margin={"1"}
                  variant="outlined"
                  style={{ width: "100%" }}
                  className="form-control"
                >
                  <InputLabel>Key</InputLabel>
                  <Select
                    value={selectedKey}
                    onChange={(e) => setSelectedKey(e.target.value)}
                  >
                    <MenuItem value={""}>Select Key</MenuItem>
                    <MenuItem value={"about"}>about</MenuItem>
                    <MenuItem value={"legal"}>legal</MenuItem>
                    <MenuItem value={"community"}>community</MenuItem>
                    <MenuItem value={"help"}>help</MenuItem>
                    <MenuItem value={"membership"}>membership</MenuItem>
                    <MenuItem value={"blog"}>blog</MenuItem>
                    <MenuItem value={"mobile"}>mobile</MenuItem>
                  </Select>
                </FormControl>
                {currentTextError && (
                  <Typography sx={{ color: textError }}>
                    {currentTextError}
                  </Typography>
                )}
              </Grid>
              <Grid item lg={5} md={6} sm={6} xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  label="Title"
                  variant="outlined"
                  value={currentText}
                  onChange={(e) => setCurrentText(e.target.value)}
                  onClick={() => setCurrentTextError("")}
                  helperText={currentTextError}
                  error={Boolean(currentTextError)}
                  className="text-field"
                  sx={{ mb: 3, mt: 2 }}
                />
              </Grid>
              <Grid item lg={1} md={6} sm={6} xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNewData}
                  sx={{ backgroundColor: "#0432A3" }}
                  disabled={
                    !getRoleAndpermission(
                      roleAndPermission,
                      "Footer Management",
                      "edit"
                    )
                  }
                  className="add-button"
                >
                  +
                </Button>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        sx={{ mt: 4, backgroundColor: "#0432A3", width: "160px" }}
        disabled={
          !getRoleAndpermission(roleAndPermission, "Footer Management", "edit")
        }
        className="submit-button"
      >
        Update Footer
      </Button>
    </form>
  );
}
